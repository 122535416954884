export const lanGs ={
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Введите правильный ИИН': {
        nameRu: "Введите правильный ИИН",
        nameKz: "Дұрыс ЖСН енгізіңіз",
    },

    'Введите правильный БИН': {
        nameRu: "Введите правильный БИН",
        nameKz: "Дұрыс БСН енгізіңіз",
    },
    'Введите только буквы': {
        nameRu: "Введите только буквы",
        nameKz: "Тек әріптерді енгізіңіз",
    },
    'Введите телефонный номер в формате +7(777)777 77 77': {
        nameRu: "Введите телефонный номер в формате +7(777)777 77 77",
        nameKz: "Телефон нөмірін +7(777)777 77 77 форматында енгізіңіз",
    },
    'Регистрация пользователя': {
        nameRu: "Регистрация пользователя",
        nameKz: "Пайдаланушыны тіркеу",
    },
    'Физическое лицо': {
        nameRu: "Физическое лицо",
        nameKz: "Жеке тұлға",
    },
    'Юридическое лицо': {
        nameRu: "Юридическое лицо",
        nameKz: "Заңды тұлға",
    },
    'Фамилия': {
        nameRu: "Фамилия",
        nameKz: "Тегі",
    },
    'Название компании': {
        nameRu: "Название компании",
        nameKz: "Компания атауы",
    },
    'Имя': {
        nameRu: "Имя",
        nameKz: "Аты",
    },
    'Отчество': {
        nameRu: "Отчество",
        nameKz: "Әкесінің аты",
    },
    'ИИН': {
        nameRu: "ИИН",
        nameKz: "ЖСН",
    },
    'БИН': {
        nameRu: "БИН",
        nameKz: "БСН",
    },
    'Контакты': {
        nameRu: "Контакты",
        nameKz: "Байланыс",
    },
    'Электронная почта': {
        nameRu: "Электронная почта",
        nameKz: "Электрондық пошта",
    },
    'Требования к паролю:': {
        nameRu: "Требования к паролю:",
        nameKz: "Парольге қойылатын талаптар:",
    },
    'не менее 6 символов': {
        nameRu: "не менее 6 символов",
        nameKz: "кемінде 6 таңба",
    },
    'содержать цифры': {
        nameRu: "содержать цифры",
        nameKz: "сандарды қамтиды",
    },
    'содержать спецсимвол': {
        nameRu: "содержать спецсимвол",
        nameKz: "арнайы таңбадан тұрады",
    },
    'буквы в нижнем регистре': {
        nameRu: "буквы в нижнем регистре",
        nameKz: "кіші әріптер",
    },
    'буквы в верхнем регистре': {
        nameRu: "буквы в верхнем регистре",
        nameKz: "бас әріптер",
    },
    'Пароль': {
        nameRu: "Пароль",
        nameKz: "Құпия сөз",
    },
    'не соответствует требованиям': {
        nameRu: "не соответствует требованиям",
        nameKz: "талаптарға сәйкес келмейді",
    },
    'Согласие о предоставлении персональных данных': {
        nameRu: "Согласие о предоставлении персональных данных",
        nameKz: "Дербес деректерді беру туралы келісім",
    },
    'Ознакомился (-ась) и согласен (-а)': {
        nameRu: "Ознакомился (-ась) и согласен (-а)",
        nameKz: "Мен таныстым және келісемін",
    },
    'Отменить': {
        nameRu: "Отменить",
        nameKz: "Бас тарту",
    },
    'Зарегистрировать': {
        nameRu: "Зарегистрировать",
        nameKz: "Тіркеу",
    },
    'СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ': {
        nameRu: "СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ",
        nameKz: "ДЕРБЕС ДЕРЕКТЕРДІ ӨҢДЕУГЕ КЕЛІСІМ",
    },
    'textReg': {
        nameRu: "Пользуясь Сервисом предложения инициатив даю свое согласие на сбор, обработку моих\n" +
            "персональных данных АО «Центр развития города Алматы (далее – Общество), включая (без\n" +
            "ограничения): фамилия, имя, отчество; национальность; пол; возраст; дата и место рождения; тип\n" +
            "документа, удостоверяющего личность; данные документа, удостоверяющего личность;\n" +
            "гражданство; адрес регистрации по месту жительства или месту пребывания; адрес фактического\n" +
            "проживания; документ, подтверждающий законность нахождения на территории Республики\n" +
            "Казахстан; номера личных телефонов и адреса электронной почты; портретное изображение;\n" +
            "семейное положение; родственные связи; наличие детей; сведения о доходах, имуществе и\n" +
            "обязательствах; судимость; данные о наличии и группы инвалидности, степени ограничения к\n" +
            "трудовой деятельности, социальных льготах и социальном статусе; образование; место работы.\n" +
            "Даю согласие на использование моих персональных данных в целях оценки моей\n" +
            "правосубъектности; рисков при принятии решения о заключении и изменении условий\n" +
            "гражданско-правовых договоров, мониторинге их исполнения; осуществления и защиты\n" +
            "Обществом его прав и законных интересов.\n" +
            "Даю согласие на передачу моих персональных данных третьим лицам, связанным с\n" +
            "Обществом правоотношениями по сбору, обработке и передаче персональных данных, частным\n" +
            "нотариусам, частным судебными исполнителям, адвокатам и государственным органам.\n" +
            "Настоящее согласие предоставляется мной на осуществление действий в отношении моих\n" +
            "персональных данных, которые необходимы для достижения указанных выше целей, включая (без\n" +
            "ограничения) получение, систематизацию, накопление, хранение на электронных, бумажных и\n" +
            "(или) иных материальных носителях, изменение, дополнение, использование, распространение,\n" +
            "обезличивание, блокирование и уничтожение персональных данных, а также осуществление\n" +
            "любых иных действий, предусмотренных действующим законодательством Республики\n" +
            "Казахстан.\n" +
            "Я проинформирован, что Общество гарантирует обработку моих персональных данных в\n" +
            "соответствии с действующим законодательством Республики Казахстан.\n" +
            "Данное согласие действует до достижения целей обработки персональных данных или в\n" +
            "течение срока хранения информации.\n" +
            "Данное согласие не может быть отозвано, если это противоречит законам Республики\n" +
            "Казахстан, либо при наличии неисполненного обязательства.\n" +
            "Я подтверждаю, что, давая такое согласие, я действую по собственной воле и в своих\n" +
            "интересах.",
        nameKz: "Бастамаларды ұсыну сервисін пайдалана отырып, мен өзімді жинауға, өңдеуге өз келісімімді беремін\n" +
            "Алматы қаласын дамыту орталығы \"АҚ (бұдан әрі – қоғам) дербес деректерін қоса алғанда (Онсыз\n" +
            "шектеулер): Тегі, Аты, Әкесінің аты; ұлты; жынысы; жасы; туған күні мен орны; түрі\n" +
            "жеке басын куәландыратын құжаттың деректері; жеке басын куәландыратын құжаттың деректері;\n" +
            "Азаматтығы; тұрғылықты жері немесе тұратын жері бойынша тіркеу мекенжайы; нақты мекенжайы\n" +
            "тұрғылықты жері; республика аумағында болуының заңдылығын растайтын құжат\n" +
            "Қазақстан; жеке телефон нөмірлері және электрондық пошта мекенжайлары; портреттік сурет;\n" +
            "отбасы жағдайы; туыстық байланыстар; балалардың болуы; табысы, мүлкі және\n" +
            "; соттылығы; мүгедектіктің бар-жоғы және Тобы, шектеу дәрежесі туралы деректер\n" +
            "еңбек қызметі, әлеуметтік жеңілдіктер және әлеуметтік мәртебе; білімі; жұмыс орны.\n" +
            "Менің дербес деректерімді бағалау мақсатында пайдалануға келісім беремін\n" +
            "шарттарды жасасу және өзгерту туралы шешім қабылдау кезіндегі тәуекелдер\n" +
            "азаматтық-құқықтық шарттар, олардың орындалуын мониторингілеу; жүзеге асыру және қорғау\n" +
            "Қоғам оның құқықтары мен заңды мүдделерін қорғайды.\n" +
            "Менің дербес деректерімді үшінші тұлғаларға беруге келісім беремін\n" +
            "Дербес деректерді жинау, өңдеу және беру жөніндегі құқықтық қатынастар қоғамы\n" +
            "нотариустарға, жеке сот орындаушыларына, адвокаттарға және мемлекеттік органдарға.\n" +
            "Осы Келісімді мен өзіме қатысты әрекеттерді жүзеге асыруға беремін\n" +
            "жоғарыда көрсетілген мақсаттарға қол жеткізу үшін қажетті дербес деректерді қоса алғанда (онсыз\n" +
            "шектеулер) алу, жүйелеу, жинақтау, электрондық, қағаз және\n" +
            "(немесе) өзге де материалдық жеткізгіштерде өзгерту, толықтыру, пайдалану, тарату,\n" +
            "дербес деректерді иесіздендіру, бұғаттау және жою, сондай-ақ\n" +
            "Республиканың қолданыстағы заңнамасында көзделген кез келген өзге де іс-қимылдар\n" +
            "Қазақстан.\n" +
            "Қоғам менің жеке деректерімді өңдеуге кепілдік беретіні туралы маған хабарланды\n" +
            "Қазақстан Республикасының қолданыстағы заңнамасына сәйкес.\n" +
            "Бұл келісім дербес деректерді өңдеу мақсаттарына қол жеткізгенге дейін немесе\n" +
            "ақпаратты сақтау мерзімінің өтуі.\n" +
            "Егер бұл республика заңдарына қайшы келсе, бұл келісімді кері қайтарып алуға болмайды\n" +
            "Қазақстан немесе орындалмаған міндеттеме болған жағдайда.\n" +
            "Мен осындай келісім беру арқылы өз еркіммен және өз еркіммен әрекет ететінімді растаймын\n" +
            "мүдделер.",
    },
    'Не выбрано': {
        nameRu: "Не выбрано",
        nameKz: "Таңдалмаған",
    },
    'USER_EMAIL_EXISTS_ERROR': {
        nameRu: "Пользователь, с таким email уже существует",
        nameKz: "Мұндай email бар пайдаланушы қазірдің өзінде бар",
    },
    'USER_BIN_EXISTS_ERROR': {
        nameRu: "Пользователь с таким ИИН/БИИН уже существует",
        nameKz: "Мұндай ЖСН/БСН бар пайдаланушы қазірдің өзінде бар",
    },
    'regOk':{
        nameRu: "Учетная запись создана, проверьте Email для подтверждения",
        nameKz: "Тіркелгі жасалды, растау үшін электрондық поштаны тексеріңіз",
    }

}



