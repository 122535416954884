import React from 'react';
import {FixedSizeList} from "react-window";

import DEF from "../Image/def.svg";
import {ReactComponent as Mark} from "../Image/mark.svg";
import {ReactComponent as Cat} from "../Image/cat.svg";
import {Tooltip} from "antd";
import {lanGs} from "../langData";


let imgUrl = '/uploads/gis/infra/category/icon/'
const VirtualCard = ({data, cardSelect, setCardSelect, option, lang, setToMark, setClickInitiative}) => {
    const Row = ({data, style, index}) => (
        <div className={"sbcontent card_item_wrapper"} key={index} style={style}>
            {data[index]}
        </div>
    );

    const componentItems = data.map(i => {
        return (
            <div
                className={`card_item_wrap iniMap ${cardSelect && cardSelect.id === i.id ? 'active' : ''}`}
                onClick={() => {
                    setToMark(null);
                    setClickInitiative(i.id)
                }} key={i.id}>
                <div className={'alternative_cat_name'}>
                    {i?.name ?
                        <Tooltip placement="bottom" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'} title={`${i?.name}`}>
                            <span className={'card_item first'}>
                        {i?.name}</span>
                        </Tooltip> :
                        <span className={'card_item first'}>Не указанно</span>}
                    <svg width="50" height="50" viewBox="0 0 45 55" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="22.5" cy="22.5" r="20" stroke={`${i?.status?.color ? `${i?.status?.color}` : '#005797'}`} strokeWidth="2"/>
                        <image x="8.5" y="7" width="28" height="28"
                               href={`${i?.categoryInitiative?.iconMap ? i.categoryInitiative.iconMap : DEF}`}
                        />
                    </svg>
                </div>

                {i?.categoryInitiative &&
                    <div className={'alternative_cat'}>
                        <Tooltip placement="bottom" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'}
                                 title={i?.categoryInitiative && (lanGs['Категория инициативы:'][lang] + ' ' + i.categoryInitiative?.name[lang])}>
                            <div className={'Tooltip_wrap_text_blocks'}>
                                <Cat/>
                                <span className={'Tooltip_wrap_text_block_text'}> {lanGs['Категория:'][lang]} </span>
                                <span> {i?.categoryInitiative && i.categoryInitiative?.name[lang]}</span>
                            </div>
                        </Tooltip>
                        <Tooltip placement="bottom" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'}
                                 title={i?.categoryWork && (lanGs['Категория работы:'][lang] + ' ' + i.categoryWork?.name[lang])}>
                            <div className={'Tooltip_wrap_text_blocks'}>
                                <Cat/>
                                <span className={'Tooltip_wrap_text_block_text'}>{lanGs['Работа:'][lang]} </span>
                                <span>{i?.categoryWork && i.categoryWork?.name[lang]}</span>
                            </div>
                        </Tooltip>

                        <Tooltip placement="bottom" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'}
                                 title={i?.categoryObject && (lanGs['Категория объекта инициативы:'][lang] + ' ' + i.categoryObject?.name[lang])}>
                            <div className={'Tooltip_wrap_text_blocks'}>
                                <Cat/>
                                <span className={'Tooltip_wrap_text_block_text'}>{lanGs['Объект:'][lang]} </span>
                                <span>{i?.categoryObject && i.categoryObject?.name[lang]}</span>
                            </div>
                        </Tooltip>



                    </div>
                }

                {/*{i?.categoryWork &&
                    <Tooltip
                        placement="bottom"
                        color={'#146eb0'}
                        overlayClassName={'Tooltip_wrapper'}
                        title={i?.categoryWork && i.categoryWork?.name[lang] + ' / ' + i.categoryObject.name[lang]
                        }>
                    <span className={'card_item second swap_text_one_Line'}>
                        {i?.categoryInitiative && i.categoryWork?.name[lang] + ' / ' + i.categoryObject.name[lang]}
                    </span>
                    </Tooltip>
                }*/}
                <div className={'alternative_cat_tr'}>
                    {
                        <Tooltip
                            placement="bottom"
                            color={'#146eb0'}
                            overlayClassName={'Tooltip_wrapper'}
                            title={`${lanGs['Район'][lang]}: ${i?.district ? i.district[lang] : lanGs['Не указан'][lang]}`}>
                            <div className={'card_item forth'}>
                                <span><Mark/></span>
                                <span>{i?.district ? i.district[lang] : lanGs['Не указан'][lang]}</span>
                            </div>
                        </Tooltip>
                    }
                    {
                        <Tooltip
                            placement="bottom"
                            color={'#146eb0'}
                            overlayClassName={'Tooltip_wrapper'}
                            title={`${lanGs['Статус'][lang]}: ${i?.status ? i.status.name[lang] : ''}`}>
                            <div className={'card_item forth'}>
                                <span style={{ color: `${i.status.color}` }}>⬤ </span>
                                <span>{lanGs['Статус'][lang]}:</span>
                                <span style={{ color: `${i.status.color}` }}> {i?.status ? i.status.name[lang] : ''}</span>
                            </div>
                        </Tooltip>
                    }
                </div>

            </div>
        );
    });


    return (
        <FixedSizeList

            height={window.innerHeight - 280}
            // width={330}
            itemCount={componentItems.length}
            itemSize={option?.heightCard ? option?.heightCard : 200}
            itemData={componentItems}
        >
            {Row}
        </FixedSizeList>


    );

}

export default VirtualCard;

