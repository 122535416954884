import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function GoogleAnalytics() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const gtag = window.gtag;

        if (navigate && location.pathname && typeof gtag === 'function') {
            gtag('config', 'UA-87269926-3', {
                page_title: document.title,
                page_location: window.location.href,
                page_path: location.pathname,
            });
        }
    }, [location, navigate]);

    return null;
}

export default GoogleAnalytics;

