import './App.css';
import GoogleAnalytics from "./utils/Analitics/GoogleAnalitics";
import Initiatives from "./components/Initiatives";
import './utils/reset.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './utils/updateCss.css'



function App() {
  return (
      <BrowserRouter>
    <div className="App">

       <Initiatives/>

    </div>
        <GoogleAnalytics/>
      </BrowserRouter>
  );
}

export default App;
