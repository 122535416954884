import React, {useEffect, useState} from "react";
import "../Style/CardsPanel.css";
import {Cascader, Form, Input} from "antd";
import "react-virtualized/styles.css";
import {ReactComponent as FilterIcon} from '../Image/filter.svg'
import VirtualCard from "./VirtualCard";

import {lanGs} from "../langData";
import {uniqueItems} from "../../../.../../../utils/helper";

const CardsPanel = ({
  data,
  cardSelect,
  setCardSelect,
  setToMark,
  setFilterStatus,
  cardData,
  lang,
                      setClickInitiative,
  show,
  isLoad,
                      filterStatus, tabBtn
}) => {
  const [filtered, setFiltered] = useState(null);
  const [formOne] = Form.useForm();
  // const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const open = "sidebar ";
  const closed = "sidebar sbclosed";

  const onSearch = (e) => {

    setFiltered(
      data.filter((i) => {
        return (
          (i.name &&
            i.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
          (i.address &&
            i.address.toLowerCase().includes(e.target.value.toLowerCase()))
        );
      })
    );
  };

  const onChanges = (e) => {

    setCardSelect(false);
    formOne.resetFields();
    setFilterStatus(e !== undefined ? e[0] : null);
  };

  useEffect(() => {
    // form1.resetFields()

    setFiltered(filterStatus ? data.filter(i => i.status.id === filterStatus) : data);
  }, [data, filterStatus]);

  return (
    <div className={"sidebar_wrapper"}>
      {<div className={`sidebar_items iniMaps ${show === true ? open : closed}`}>
        {show && <div className={"sidebar_wrap"}>
          <div className={"sidebar_filter_wrapper"}>
                <span className={"sidebar_filter_search"}>
                  <Form name="basic123" form={formOne}>
                    <div className={"LNav_filters search_block"}>
                      <Form.Item name={"test1"}>
                        <Input
                          allowClear
                          placeholder={
                            data?.length > 0 &&
                            data[0].name &&
                            lanGs["Поиск по названию"][lang]
                          }
                          onChange={onSearch}
                        />
                      </Form.Item>
                    </div>
                  </Form>
                </span>
            <div className={"sidebar_filter_wrap"}>
              <div className={"sidebar_filter_filt"}>
                {data && data[0]?.status && tabBtn &&
                  <Form name="basic" form={form2}>
                    <div className={"LNav_filters sort_block"}>
                      <div className={"LNav_filters_Cascader"}>
                                                    <span className={"iconFilterLayer"}>
                                                         <Form.Item name={"test2"}>
                                                             <Cascader options={uniqueItems(data, "status", "id").map(i => {
                                                               return { value: i.id, label: i.name[lang] };
                                                             })}
                                                                       onChange={onChanges} suffixIcon={<FilterIcon />}
                                                                       popupClassName={"iconFilterLayer_popup"} />
                                                         </Form.Item>
                                                    </span>
                      </div>
                    </div>
                  </Form>
                }
              </div>
              <span className={"sidebar_filter_length"}>
                    {" "}
                {lanGs["найдено"][lang]}: {filtered?.length}
                  </span>
            </div>
          </div>
          {data && (
            <VirtualCard
              itemCount={1000000000}
              data={filtered ? filtered : data}
              cardSelect={cardSelect}
              setCardSelect={setCardSelect}
              setToMark={setToMark}
              lang={lang}
              setClickInitiative={setClickInitiative}

            />
          )}
        </div>}
      </div>}
    </div>
  );
};

export default CardsPanel;
