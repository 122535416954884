import React, {useEffect, useState} from 'react';
import './GisSMR.css'
import GisMap from "./Map/GisMap";

import axios from "axios";
import DistrictNav from "./ui/DistrictNav";
import CardList from "./Map/CardList";
import {Button, Spin} from "antd";
import CardPassport from "./Map/CardPassport";
import CategoryRadio from "./ui/CategoryRadio";
import moment from "moment";
import FeedbackItem from "./Map/feedbackItem";
import {filterData} from "../../../utils/helper";

const GisSMR = ({lang, setLang}) => {
    /*Первичные данные*/
    const [distData, setDistData] = useState(null) // хранит данные для отрисовки навигации по районам
    const [data, setData] = useState(null) // хранит данные меток
    const [likeData, setLikeData] = useState(null) // хранит список опросника по отзывам
    /*Действия*/
    const [filterStatus, setFilterStatus] = useState(null);
    const [isFilterData, setIsFilterData] = useState(true);
    const [cardData, setCardData] = useState(null);
    const [cardSelect, setCardSelect] = useState(null)
    const [toMark, setToMark] = useState(null)
    const [distChoice, setDistChoice] = useState(null)
    /*фильтры категорий*/
    const [workStatus, setWorkStatus] = useState(null)// хранит данные для отрисовки справочника статусов
    const [depData, setDepData] = useState(null)// хранит данные для отрисовки справочника департаментов
    const [dataCat, setDataCat] = useState(null) // хранит данные для отрисовки категорий
    /*фильтры категорий => действия*/
    const [isPeriod, setIsPeriod] = useState(null);// хранит данные выбранного периода
    const [departmentId, setDepartmentId] = useState(null);// хранит данные выбранного департамента
    const [isStatus, setIsStatus] = useState(null);// хранит данные выбранного статуса работ
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);// хранит данные выбранной категории
    const [likeUpdate, setLikeUpdate] = useState(null); // обновление списка лайков
    /*управление*/
    const [show, setShow] = useState(true); // Скрывает боковую панель
    const [openLike, setOpenLike] = useState(false); // Скрывает панель лайков
    const [clickLike, setClickLike] = useState("like"); // Дефолтное значение отзыва


    useEffect(() => {
        axios.get(`/sc-gis/api/v1/repair-work/district`).then(res => {
            setDistData(res.data)
            setDistChoice(res.data.find(item => item?.id === 0))
        })
        axios.get(`/sc-gis/api/v1/ref/repair-work-status`).then(res => {
            setWorkStatus(res.data);
        });
        axios.get(`/sc-gis/api/v1/ref/department`).then(res => {
            setDepData(res.data);
        });

    }, []);
    useEffect(() => {
        setData(null)
        setDataCat(null)
        let dataSend = {
            distId: distChoice && distChoice.id !== 0 ? distChoice.id : null,
            subCatId: selectedCheckbox ? selectedCheckbox : null,
            startDate: isPeriod ? moment(isPeriod[0]).format('DD.MM.YYYY') : moment().subtract(1, 'years').format('DD.MM.YYYY'),
            endDate: isPeriod ? moment(isPeriod[1]).format('DD.MM.YYYY') : moment().add(50, 'years').format('DD.MM.YYYY'),
            statusId: isStatus,
            departmentId: departmentId,
        }
        axios({
            method: "post",
            url: `/sc-gis/api/v1/repair-work`,
            data: dataSend,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => {
                setData(res.data);
            })
            .catch(function (error) {
            });
        axios({
            method: "post",
            url: `/sc-gis/api/v1/repair-work/category`,
            data: dataSend,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => {
                setDataCat(res.data);
            })
            .catch(function (error) {
            });

    }, [distChoice, selectedCheckbox, isStatus, departmentId, isPeriod]);

    useEffect(() => {
        cardSelect && axios.get(`/sc-gis/api/v1/repair-work/${cardSelect?.id}`).then(res => {
            setLikeUpdate(null);
            setCardData(res.data);
        });

    }, [cardSelect, likeUpdate]);

    useEffect(() => {
        clickLike &&
        axios.get(`/sc-gis/api/v1/repair-work/poll`).then((res) => {
            setLikeData(res.data);
        });
    }, [clickLike, openLike]);
    useEffect(() => {
        if (data && filterStatus && filterStatus.length > 0) {
            setIsFilterData(data.filter((i) => filterStatus.includes(i.status.id)));
        } else {
            setIsFilterData(data);
        }
    }, [data, filterStatus]);
let BigData = isFilterData?.length > 0 ? isFilterData : data
    return (
        <div className={`GisSMR_wrapper`}>
            <DistrictNav
                lang={lang}
                distChoice={distChoice}
                setDistChoice={setDistChoice}
                setToMark={setToMark}
                setData={setData}
                setCardSelect={setCardSelect}
                distData={distData}

            />
            <div className={`GisSMR_wrap`}>
                <GisMap
                    lang={lang}
                    Data={BigData}
                    distChoice={distChoice}
                    isFilterData={isFilterData}
                    setCardSelect={setCardSelect}
                    toMark={toMark}
                    cardSelect={cardSelect}
                    distData={distData}
                    setToMark={setToMark}
                />

                <div className={'CardList_wrapper'}>
                    {data && (
                        <CardList
                            data={data}
                            cardSelect={cardSelect}
                            setCardSelect={setCardSelect}
                            setToMark={setToMark}
                            lang={lang}
                            show={show}
                            isFilterData={isFilterData}
                            setIsFilterData={setIsFilterData}
                            setFilterStatus={setFilterStatus}
                        />
                    )}
                    <div className={'CardList_wrap'}>
                        <div className={`map_element_items ${show && ("nav_show_" + show)}`}>
                            {cardData && cardSelect &&
                                <CardPassport
                                    setToMark={setToMark}
                                    cardData={cardData}
                                    lang={lang}
                                    setOpenLike={setOpenLike}
                                    setLikeUpdate={setLikeUpdate}
                                />
                            }
                            {likeData && cardData?.id?.toString() && openLike && (
                                <FeedbackItem
                                    likeData={likeData}
                                    lang={lang}
                                    clickLike={clickLike}
                                    setClickLike={setClickLike}
                                    cardId={cardData.id}
                                    setLikeUpdate={setLikeUpdate}
                                    setOpenLike={setOpenLike}
                                />
                            )}
                            {data && <div className={'sidebar_arrow_wrap'}>
                                <Button onClick={() => setShow(!show)}>
                                    {show === true ? "<" : ">"}
                                </Button>
                                {cardSelect && <Button onClick={() => {
                                    setCardData(null)
                                    setCardSelect(null);
                                    setToMark(null)

                                }}>X</Button>}
                            </div>}
                        </div>
                    </div>
                </div>


            </div>
            <CategoryRadio
                dataCat={filterData(dataCat)}
                setData={setData}
                lang={lang}
                setCardSelect={setCardSelect}
                setToMark={setToMark}
                workStatus={workStatus}
                depData={depData}
                selectedCheckbox={selectedCheckbox}
                setSelectedCheckbox={setSelectedCheckbox}
                setIsStatus={setIsStatus}
                setDepartmentId={setDepartmentId}
                setIsPeriod={setIsPeriod}
            />
            {!BigData && (
                <Spin
                    size="large"
                    spinning={!BigData}
                    className={`SPIN_item ${!BigData ? "active" : ""}`}
                    indicator={
                        <div className={"SPIN_item_spin"}>
                            <span className="loader"></span> <span>Загрузка...</span>
                        </div>
                    }
                ></Spin>
            )}

        </div>
    );
};

export default GisSMR;
