import React, {useEffect, useState} from 'react';
import './AddInitiatives.css'
import {Button, Form, Input, message, Modal, Select, Upload, Progress, AutoComplete, Popover, Cascader} from "antd";
import {QuestionCircleOutlined, RedoOutlined, UploadOutlined, WarningOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import MapBlock from "../ui/MapBlock";
import BackBlock from "../element/BackBlock";
import axios from "axios";
import Cookies from "js-cookie";
import {lanGs} from "../../utils/lang/langAddInitiatives";

const AddInitiatives = ({lang, setCatCardClick, setResetCardMain, setData, setDistChoice}) => {
    const [placemarkGeometry, setPlacemarkGeometry] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sendForm, setSendForm] = useState(null);
    const [dataCat, setDataCat] = useState(null);
    const [dataWork, setDataWork] = useState(null);
    const [dataObj, setDataObj] = useState(null);

    const [dataSource, setDataSource] = useState(null);
    const [dataDist, setDataDist] = useState(null);

    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const [allowedFileExtensions] = useState(['pdf', 'xlsx','xls', 'doc','docx', 'jpg', 'jpeg', 'png']); // Допустимые расширения файлов
    const [form] = Form.useForm();
    const onFinish = (values) => {
        setSendForm({ ...values, files: fileList });
        setIsModalOpen(true);

    };
    const token = Cookies.get('jwt')

    const [fileList, setFileList] = useState([]);

    useEffect(() => {

        if (token) {
            axios.get(`/sc-initiatives/api/v1/ref/cat-initiative`).then((res) => {
                setDataCat(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/cat-work`).then((res) => {
                setDataWork(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/cat-object`).then((res) => {
                setDataObj(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/fin-source`).then((res) => {
                setDataSource(res.data);
            })
            axios.get(`/sc-initiatives/api/v1/ref/district`).then((res) => {
                setDataDist(res.data);
            })
        }
        else {
            navigate('/login')
        }
    }, [token]);

    const onFinishOk = (values) => {
        setIsModalOpen(true);
        const streetId = form.getFieldValue('streetId');
        sendForm && axios({
            method: "post",
            url: "/sc-initiatives/api/v1/initiative/create",
            data:{
                "districtId": sendForm.InitiativesDist,
                "streetId": streetId? streetId: null,
                "building": sendForm?.InitiativesBuild ? sendForm.InitiativesBuild: null,
                "categoryInitiativeId": sendForm.InitiativesCat? sendForm.InitiativesCat: null,
                "categoryObjectId": sendForm?.InitiativesObj?.length? sendForm.InitiativesObj[0]: null,
                "categoryWorkId": sendForm.InitiativesWork? sendForm.InitiativesWork: null,
                "finSourceId": sendForm.source? sendForm.source: null,
                "name": sendForm.itiativesShortName? sendForm.itiativesShortName: null,
                "geom": placemarkGeometry && placemarkGeometry.length > 0? {
                    "type": "Point",
                    "coordinates": [placemarkGeometry[1], placemarkGeometry[0]]
                }:null ,
                "description": sendForm.itiativesName? sendForm.itiativesName: null,
                "suggestion": sendForm.offer? sendForm.offer: null,
                files: fileList.map((file) => ({
                    url: file.url, // изменено с file.response.url на file.url
                    type: file.type,
                    filename: file.name
                }))
            },
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setIsModalOpen(false);
                messageApi.open({
                    type: 'success',
                    content: `${lanGs['SEND_INITIATIVES'][lang]}`,
                    duration: 5,
                });
                setResetCardMain(true)
                setTimeout(() => navigate('/'), 2000)
            })
            .catch(function (error) {
                messageApi.open({
                    type: 'error',
                    content: `${lanGs['DEF_ERROR'][lang]},`,
                    duration: 5,
                });
                if(error.request.statusText === 'Unauthorized' ){
                    messageApi.open({
                        type: 'error',
                        content: `${lanGs['Unauthorized'][lang]}`,
                        duration: 5,
                    }, );
                    setTimeout(function() {

                        Cookies.remove('jwt')
                        navigate('/login?redirect=/add-initiatives')
                    }, 1000)
                }
            });

        setIsModalOpen(false);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        const fileList = e?.fileList || [];
        const errorFiles = fileList.filter((file) => {
            const extension = file.name.split('.').pop().toLowerCase();
            return !allowedFileExtensions.includes(extension);
        });

        if (errorFiles.length > 0) {
            message.error(`Недопустимый тип файла: ${errorFiles.map((file) => file.name).join(', ')}`);
            return [];
        }

        return fileList;
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const props = {
        name: 'file',
        method: 'post',
        action: '/cdn/api/v1/upload/initiative/docs',
        headers: { authorization: `Bearer ${token}`, 'api-key': 'Njg3MTU0MjYxLCJleHAiO' },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                const { response } = info.file;
                setFileList((prevFileList) => [
                    ...prevFileList,
                    {
                        uid: info.file.uid,
                        name: info.file.name,
                        status: info.file.status,
                        type: info.file.type,
                        url: response && response.url,
                    },
                ]);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file) => {
            handleRemove(file);
        }
    };
    const [options, setOptions] = useState([]);
    const handleSearch = (value) => {
        if (value) {
            // Проверяем, содержит ли введенное значение символы, не являющиеся кириллицей
            const hasNonCyrillic = /[^а-яё]/i.test(value);

            if (!hasNonCyrillic) {
                axios.get(`/sc-initiatives/api/v1/ref/street?q=${value}`)
                    .then((response) => {
                        const transformedOptions = response.data.map((item) => ({
                            value: item.name[lang],
                            label: item.name[lang],
                            id: item.id,
                        }));
                        setOptions(transformedOptions);
                    })
                    .catch((error) => {
                        // Обработайте ошибку, если необходимо
                    });
            } else {
                // Если введен текст, содержащий символы не из кириллицы, выводим сообщение, очищаем список опций и поле ввода
                message.warning('Пожалуйста, используйте только кириллицу для ввода.');
                setOptions([]);
                form.setFieldsValue({ 'InitiativesStr': '' });
            }
        } else {
            setOptions([]);
        }
    };
    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
    };


    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    return (
        <div className={'AddInitiatives_wrapper'}>
            {contextHolder}
            <BackBlock lang={lang} link={'/'} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
            <div className={'AddInitiatives_wrap'}>
                <div className={'AddInitiatives_leftBlock'} >
                    <Form name="nest-messages" onFinish={onFinish} style={{maxWidth: 600,}} layout="vertical" autoComplete="off">
                        <Form.Item noStyle>
                            <div className={'AddInitiatives_title_wrap'}>
                                <span className={'AddInitiatives_title_text'}>{lanGs['Подача инициативы'][lang]}</span>
                                <Button htmlType="reset" onClick={() => setPlacemarkGeometry(null)}><RedoOutlined/></Button>
                                <Popover placement="rightTop"  content={lanGs['contentCatIni'][lang]} trigger="click" >
                                    <QuestionCircleOutlined className={'iconPopoverIni'} />
                                </Popover>
                            </div>
                        </Form.Item>

                        <Form.Item label={lanGs["Категория инициативы"][lang]} name="InitiativesCat" hasFeedback rules={[{required: true, message: lanGs['Не выбрано'][lang],},]} className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} size="large" options={dataCat?.map(i => {return {value: i.id, label: i.name[lang]}}).sort((a, b) => a.label.localeCompare(b.label))}/>

                        </Form.Item>

                        <Form.Item label={lanGs["Категория работ"][lang]} name="InitiativesWork" hasFeedback rules={[{required: true, message:lanGs['Не' +
                        ' выбрано'][lang],},]} className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} options={dataWork?.map(i => {return {value: i.id, label: i.name[lang]}}).sort((a, b) => a.label.localeCompare(b.label))} size="large"/>

                        </Form.Item>

                        <Form.Item label={lanGs["Категория объектов"][lang]} name="InitiativesObj" hasFeedback rules={[{required: true, message: lanGs['Не выбрано'][lang],},]} className={'AddInitiatives_line'}>
                            {/*<Select style={{width: '100%',}} options={dataObj?.map(i => {return {value: i.id, label: i.name[lang]}})} size="large"/>*/}
                            <Cascader
                                options={dataObj?.map(i => {return {value: i.id, label: i.name[lang]}}).sort((a, b) => a.label.localeCompare(b.label))}

                                placeholder={lanGs['Поиск категории объекта'][lang]}
                                showSearch={{
                                    filter,
                                }}
                                size="large"
                            />
                        </Form.Item>

                        <div className={'item_three_block'}>
                            <Form.Item className={'AddInitiatives_line'} label={lanGs["Район"][lang]} name={'InitiativesDist'}  rules={[
                                {required: true, message: lanGs['Не выбрано'][lang],},

                            ]}>
                                <Select style={{width: '100%',}} options={dataDist?.map(i => {return {value: i.id, label: i[lang]}})} size="large"/>
                            </Form.Item>


                            <Form.Item label={lanGs['Улица'][lang]} name={'InitiativesStr'} className={'street_style_input'} rules={[
                                // {required: true, message: lanGs['Не заполнено'][lang],},


                            ]}>
                                <AutoComplete
                                    size="large"
                                    options={options}
                                    onSearch={handleSearch}
                                    placeholder="Введите текст для поиска"
                                    onSelect={(value, option) => {
                                        form.setFieldsValue({ 'InitiativesStr': option.label });
                                        form.setFieldsValue({ 'streetId': option.id });
                                    }}
                                    onInput={(e) => {
                                        const inputValue = e.target.value;
                                        const filteredInput = inputValue.replace(/[^\p{L}\d\s]/gu, ''); // Разрешаем кириллицу, цифры и пробелы

                                        if (inputValue !== filteredInput) {
                                            e.target.value = filteredInput;
                                            message.warning('Пожалуйста, используйте только кириллические символы и цифры.');
                                        }
                                    }}
                                />
                            </Form.Item>


                            <Form.Item label={lanGs['№ дома'][lang]} name={'InitiativesBuild'} rules={[
                                // {required: true, message: lanGs['Не заполнено'][lang],},
                                {max: 20, message: lanGs['Лимит символов:'][lang] + ' 20',},

                            ]} style={{minWidth: '70px'}}>
                                <Input size="large" className={''} />
                            </Form.Item>

                        </div>

                        <Form.Item label={lanGs['Краткое название инициативы'][lang]} name="itiativesShortName"
                                   rules={[
                                       {required: true, message: lanGs['Не заполнено'][lang],},
                                       {max: 50, message: lanGs['Лимит символов:'][lang] + ' 50',},
                                   ]} className={'AddInitiatives_line'}>
                            <Input size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Подробное описание инициативы"][lang]}
                                   name="itiativesName"
                                   rules={[
                                       {required: true, message:lanGs['Не заполнено'][lang] ,},
                                       {max: 1000, message: lanGs['Лимит символов:'][lang] +  ' 1000',},
                                   ]}
                                   className={'AddInitiatives_line'}>
                            <TextArea size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Предлагаемые пути решения"][lang]} name="offer" rules={[
                            {required: true, message: lanGs['Не заполнено'][lang],},
                            {max: 2000, message: lanGs['Лимит символов:'][lang] +  ' 2000',},
                        ]} className={'AddInitiatives_line'}>
                            <TextArea size="large" className={''}/>
                        </Form.Item>

                        <Form.Item label={lanGs["Источник финансирования"][lang]}
                                   name="source"
                                   hasFeedback
                                   rules={[{required: true, message:lanGs['Не выбрано'][lang],},]}
                                   className={'AddInitiatives_line'}>
                            <Select style={{width: '100%',}} options={dataSource?.map(i => {return {value: i.id, label: i.name[lang]}})} size="large"/>
                        </Form.Item>

                        <Form.Item
                            name="upload"
                            label={lanGs['Добавить вложения'][lang]}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            extra={lanGs['Объем ф'][lang]}
                        >
                            <Upload
                                name="docs"
                                {...props}
                                className={'Upload_list_ini'}
                                accept={allowedFileExtensions.map((extension) => `.${extension}`).join(',')}
                            >
                                <Button icon={<UploadOutlined />}>
                                    {lanGs['Выбрать вложение'][lang]}
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item className={'btn_submit_item_wrap'}>
                            <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Отправить'][lang]}</Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={'AddInitiatives_rightBlock'}>
                    <MapBlock placemarkGeometry={placemarkGeometry} setPlacemarkGeometry={setPlacemarkGeometry} lang={lang}/>
                </div>
                <Modal title={lanGs["Подтверждение подачи инициативы"][lang]} open={isModalOpen} onOk={onFinishOk} onCancel={handleCancel} centered className={'AddInitiatives_modal'}>
                    <p>{lanGs['Вы действительно хотите отправить заявку на рассмотрение?'][lang]}</p>
                </Modal>
            </div>
        </div>
    );
};
export default AddInitiatives;
