export const lanGs = {
    'широта:': {
        nameRu: "широта:",
        nameKz: "ендік",
    },
    'долгота:': {
        nameRu: "долгота:",
        nameKz: "бойлық:",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
