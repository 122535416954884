import React, {useState, useEffect, useMemo, memo} from 'react';
import {MapContainer, TileLayer, useMap, ZoomControl, GeoJSON} from 'react-leaflet';
import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import './GisMap.css';
import PopupItem from "./PopupItem";
import DefIcon from './icons/def.svg'
import Polygon from '../../../../utils/PoligonAl.json'
import LegendItem from "./legendItem";

const GisMap = ({lang, Data, distChoice, isFilterData, setCardSelect, toMark, setToMark, distData}) => {
    const [data, setData] = useState(null);
    const [distBorder, setDistBorder] = useState(null);
    const [cityBorder, setCityBorder] = useState(null);
    // useMemo для трансформации данных маркеров только при изменении Data
    const markersData = useMemo(() => data && data.map(item => {
        if (item.marker) {
            try {
                const [longitude, latitude] = item.marker.coordinates;
                const popupContent = <PopupItem data={item} lang={lang}/>;
                return typeof latitude === 'number' && typeof longitude === 'number'
                    ? {
                        position: [latitude, longitude],
                        content: renderToString(popupContent), // Преобразуем компонент в строку HTML
                        iconUrl: item?.subCategory?.icon ? item?.subCategory?.icon : DefIcon, // URL иконки
                        // iconUrl: DefIcon, // URL иконки
                        color: item?.status?.color ? item?.status?.color : '#000000',
                        item: {id: item.id}
                    }
                    : null;
            } catch (error) {
                console.error('Ошибка при преобразовании данных маркера:', error);
                return null;
            }
        }
        return null;
    }).filter(marker => marker !== null), [data, lang]);

    useEffect(() => {
        setCityBorder(<GeoJSON data={Polygon} key={"AlmatyKey" + distData?.find(f => f.id === distChoice?.id).id}/>);
    }, [distChoice, distData]);
    useEffect(() => {
        if (distData && distChoice?.id !== 0) {
            let filteredShape = distData?.filter(f => f.id !== 0 && f.id !== distChoice.id);
            filteredShape && setDistBorder(filteredShape?.map(i => (<GeoJSON
                data={i.geom} key={"AlmatyKey" + i.id}/>)));
        } else {
            setDistBorder(null)
        }

    }, [distChoice, distData]);

    useEffect(() => {
        Data && setData(isFilterData ? isFilterData : Data)
    }, [Data, isFilterData]);

    const createCustomIcon = (iconUrl, color, border, id) => {
        return L.divIcon({
            html: `<div style="background-color: ${color}; border-radius: 50%; padding: 5px; display: flex; justify-content: center; align-items: center; border: 1px solid ${border}" >
                       <img src="${iconUrl}" style="width: 20px; height: 20px;" alt="">
                   </div>`,
            className: '', // Отключаем стандартные стили Leaflet
            iconSize: L.point(33, 30) // Размер иконки
        });
    };
    const createClusterCustomIcon = cluster => {
        return L.divIcon({
            html: `<div style="
                        background-color: #ffffff; 
                        width:auto; 
                        height: auto; 
                        border-radius: 50%; 
                        display: flex; 
                        justify-content: center; 
                        align-items: center; 
                        padding: 10px;
                        font-weight: 600;
                        border: 1px solid #146eb0"><span style="color: #146eb0;">${cluster.getChildCount()}</span></div>`,
            className: '', // Отключаем стандартные стили Leaflet
            iconSize: L.point(40, 40), // Размер иконки кластера
        });
    };

    // Компонент MarkerClusterGroup обернут в React.memo для предотвращения ненужных ререндеров
    const MarkerClusterGroup = memo(({markers}) => {
        const map = useMap();
        useEffect(() => {
            const markerClusterGroup = L.markerClusterGroup({
                showCoverageOnHover: false, // Отключаем показ полигона границ кластера
                iconCreateFunction: createClusterCustomIcon, // Используйте свою функцию для создания иконок кластера
                maxClusterRadius: 150, // Максимальный радиус, в пикселях, для объединения маркеров в кластер
                disableClusteringAtZoom: 18, // Отключение кластеризации на определенном уровне зума
                spiderfyOnMaxZoom: true, // Разделять маркеры при максимальном зуме

            });
            markers?.forEach(marker => {
                const customIcon = createCustomIcon(marker.iconUrl, '#ffffff', '#146eb0');
                const leafletMarker = L.marker(marker.position, {icon: customIcon}).bindPopup(marker.content);
                leafletMarker.on('dblclick', function (e) {
                    setCardSelect(marker.item)
                });
                leafletMarker.on('click', function (e) {
                    setToMark(null)
                });
                markerClusterGroup.addLayer(leafletMarker);
            });

            map.addLayer(markerClusterGroup);
            return () => map.removeLayer(markerClusterGroup);
        }, [markers, map]);

        return null;
    });

    const createMarkersAndGeoJSON = (data) => {
        const geoJSONObjects = [];
        data?.forEach(item => {
            if (item?.geom) {
                // Создаем гео-объект
                geoJSONObjects.push(
                    <GeoJSON
                        key={item.id}
                        data={item.geom}
                        style={{color: item?.status?.color ? item?.status?.color : '#000000'}}
                        onEachFeature={(feature, layer) => {

                            layer.on('dblclick', () => {

                                setCardSelect(item)


                            });
                        }}
                    />
                );
            }
        });
        return {geoJSONObjects};
    };

    const {geoJSONObjects} = useMemo(() => createMarkersAndGeoJSON(data), [createMarkersAndGeoJSON, data]);
    const CenterMap = ({toMark}) => {
        const map = useMap(); // Получаем ссылку на объект карты
        useEffect(() => {
            map.closePopup();
            map.eachLayer(function (layer) {
                layer.closePopup();
            });

            if (toMark?.category ) {

                map.flyTo([toMark.marker.coordinates[1], toMark.marker.coordinates[0]], 18); // Центрируем карту
                map.openPopup(
                    renderToString(<PopupItem data={toMark} lang={lang}/>),
                    [toMark.marker.coordinates[1], toMark.marker.coordinates[0]]
                );
            }
            else{

                toMark &&  map.flyTo([toMark.marker.coordinates[1] + 0.01, toMark.marker.coordinates[0] - 0.03], toMark.id === 0? 11.5: 13); // Центрируем
                // карту
            }

        }, [toMark, map]);

        return null; // Компонент не рендерит ничего в DOM
    };


    return (
        <MapContainer
            center={[43.250411, 76.92201]}
            zoom={11.5}
            style={{height: 'calc(100vh - 100px)', width: '100%'}}
            zoomControl={false} // Отключаем стандартные кнопки зумирования
            doubleClickZoom={false}
            crs={L.CRS.EPSG3395} // Используем проекцию EPSG:3395
        >
            {/*<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>*/}
            <TileLayer url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&lang=ru_RU&ads=enabled"/>
            <ZoomControl position="topright"/> {/* Добавляем кнопки зумирования в правый верхний угол */}
            {distBorder}
            {cityBorder}

            {/* Добавляем гео-объекты на карту */}
            {geoJSONObjects}
            <MarkerClusterGroup markers={markersData}/>
            <CenterMap toMark={toMark}/> {/* Компонент для центрирования карты */}
            {markersData && <LegendItem data={Data} lang={lang}  />
            }
        </MapContainer>
    );
};

export default GisMap;
