import React, {useEffect, useState} from 'react';

import '../Style/legendItem.css'


import DEF from "../Image/def.svg";

const LegendItem = ({data, lang}) => {


    /**/
    const [legData , setLegData] = useState(null)
    /**/
    useEffect(() => {
        data && lang &&  setLegData([...new Set(data.map(i => i.status.name[lang]))].sort().map(i => {
            let filter = data.filter(it => it.status.name[lang] === i)
            if(filter) {
                return {
                    name: i,
                    value: filter.length,
                    id: filter[0].status.id,
                    color: filter[0].status.color
                   /* icon: filter.find(e => e.status.name[lang] === i).status.icon*/

                };
            }
        }))
    }, [data, lang])

    return (
        legData ? <div className={'LMap_legend def_bauble_info _color_icon'}>
            {
                legData.map((i, index )=> {
                    return <div className={'LMap_legend_color_icon '} key={index + i.id + Math.random()}>
                        <div className={'Round_marker_icon'} style={{width: '15px', height: '15px',backgroundColor: `${i.color ? i?.color : '#005797'}`}}>

                        </div>
                        <span>{i.name} ({i.value})</span>
                    </div>
                })
            }


        </div> : null
    )
};

export default LegendItem;
