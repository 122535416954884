import React from "react";

export const lanGs = {
    'SEND_INITIATIVES': {
        nameRu: "Инициатива принята на обработку",
        nameKz: "Бастама өңдеуге қабылданды",
    },
    'DEF_ERROR': {
        nameRu: "Что то пошло не так :(",
        nameKz: "Бірдеңе дұрыс болмады :(",
    },




    'Подача инициативы': {
        nameRu: "Подача инициативы",
        nameKz: "Бастама беру",
    },
    'Категория инициативы': {
        nameRu: "Категория инициативы",
        nameKz: "Бастама санаты",
    },
    'Не выбрано': {
        nameRu: "Не выбрано",
        nameKz: "Таңдалмаған",
    },
    'Категория работ': {
        nameRu: "Категория работ",
        nameKz: "Жұмыс санаты",
    },
    'Категория объектов': {
        nameRu: "Категория объектов",
        nameKz: "Объектілер санаты",
    },
    'Район': {
        nameRu: "Район",
        nameKz: "Аудан",
    },
    'Улица': {
        nameRu: "Улица",
        nameKz: "Көше",
    },
    '№ дома': {
        nameRu: "№ дома",
        nameKz: "Үйдің №",
    },
    'Краткое название инициативы': {
        nameRu: "Краткое название инициативы",
        nameKz: "Бастаманың қысқаша атауы",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Максимальное количество символов:': {
        nameRu: "Максимальное количество символов:",
        nameKz: "Таңбалардың максималды саны:",
    },
    'Лимит символов:':{
        nameRu: "Лимит символов:",
        nameKz: "Таңбалар шегі:",
    },
    'Подробное описание инициативы': {
        nameRu: "Подробное описание инициативы",
        nameKz: "Бастаманың толық сипаттамасы",
    },
    'Предлагаемые пути решения': {
        nameRu: "Предлагаемые пути решения",
        nameKz: "Ұсынылған шешімдер",
    },
    'Источник финансирования': {
        nameRu: "Источник финансирования",
        nameKz: "Қаржыландыру көзі",
    },
    'Добавить вложения': {
        nameRu: "Добавить вложения",
        nameKz: "Тіркемелер қосу",
    },
    'Выбрать вложение': {
        nameRu: "Выбрать вложение",
        nameKz: "Тіркемені таңдаңыз",
    },
    'Отправить': {
        nameRu: "Отправить",
        nameKz: "Жіберу",
    },
    'Подтверждение подачи инициативы': {
        nameRu: "Подтверждение подачи инициативы",
        nameKz: "Бастаманы растау",
    },
    'Вы действительно хотите отправить заявку на рассмотрение?': {
        nameRu: "Вы действительно хотите отправить заявку на рассмотрение?",
        nameKz: "Сіз шынымен қарауға өтініш жібергіңіз келе ме?",
    },
    'Объем ф': {
        nameRu: "Объем файлов не более 20 МБ (.pdf, .xls, .doc .jpg, .jpeg, .png)",
        nameKz: "Файл көлемі 20 МБ-тан аспайды (.pdf, .xls, .doc .jpg, .jpeg, .png)",
    },
    'Редактирование инициативы': {
        nameRu: "Редактирование инициативы",
        nameKz: "Бастаманы редакциялау",
    },
    'Unauthorized': {
        nameRu: "Требуется повторная авторизация. Вас перенаправит на страницу авторизации",
        nameKz: "Қайта авторизациялау қажет. Сізді авторизация бетіне бағыттайды",
    },
    'onModer': {
        nameRu: "Инициатива на проверке модератора",
        nameKz: "Модераторды тексеру бастамасы",
    },
    'Поиск категории объекта': {
        nameRu: "Поиск категории объекта",
        nameKz: "Нысан санатын іздеу",
    },
    'contentCatIni':{
        nameRu: <div className={'Popover_text_wrap'}>
            <span>Если в данной форме вы не нашли категорию или улицу которая соответствует вашему запросу, вы можете <a href="https://docs.google.com/forms/d/e/1FAIpQLSeibReftR_6KsUPkT1zpWTb7M-H_pdrAuoxbj8r9qRBvy1GKw/viewform?usp=sf_link" target={'_blank'}>оставить заявку</a> для добавления категории/улицы в форму.</span>

        </div>,
        nameKz: <div className={'Popover_text_wrap'}>
            <span>Егер сіз осы формада сіздің сұранысыңызға сәйкес келетін санатты немесе көшені таппаған болсаңыз, формаға санат/көше қосу үшін <a href="https://docs.google.com/forms/d/e/1FAIpQLSeibReftR_6KsUPkT1zpWTb7M-H_pdrAuoxbj8r9qRBvy1GKw/viewform?usp=sf_link" target={'_blank'}>өтініш қалдыра</a> аласыз.</span>
        </div>,
    },


}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
