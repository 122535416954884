import React, {useState} from 'react';
import './PopupItem.css'
import {lanGs} from "../../../../utils/langData";
import {ReactComponent as Mark} from "./icons/mark.svg";
import {ReactComponent as Like} from "./icons/like.svg";

const PopupItem = ({lang, data}) => {
    let {name, category, subCategory, address, district, status, id, neg, pos, street, mkr, building, corpus} = data;
    let names = name ? name : address

    return (

        <div className={`PopupItem_wrap`}>
            {/*название*/}
            {names && (
                <div className={`Popup_items name ${names.length > 30 ? "transfer_text " : ""}`} title={"Название"}>
                    <span>{names}</span>

                </div>
            )}
            {/*Категория*/}
            {category && (
                <div className={`Popup_items category`} key={category.id + id + Math.random()} title={"Категория"}>
                    <span>{category.name[lang]}</span>
                    {subCategory && <span>{' / ' + subCategory.name[lang]}</span>}
                </div>
            )}

            {/*район*/}
            {district && (
                <div className={`Popup_items district`} title={lanGs["Район"][lang]}>
                    <div className={"popup_line_style"}>
                        <span><Mark/></span>{" "}
                        <span>{district[lang]}</span>
                    </div>
                </div>
            )}
            {/*Адрес*/}
            {address && (
                <div className={`Popup_items address`} title={lanGs["Адрес"][lang]}>
                    <span>{lanGs["Адрес"][lang]}: {address}</span>
                </div>
            )}

            <div className={"Popup_items_double"}>
                {/*статус*/}
                {status?.name && (
                    <div className={`Popup_items status`} title={lanGs["Статус"][lang]}>
                            <span style={{color: `${status.color ? status.color : "#000000"}`}}>
                                ⬤
                            </span>
                        <span>{status.name[lang]}</span>
                    </div>
                )}

                {/*лайки*/}
                {neg?.toString() && pos?.toString() && (
                    <div title={"Лайки"} className={"Popup_items_likes"}>
                        <div className={`Popup_items like_pos`}>
                            <Like/>
                            <span>{pos}</span>
                        </div>
                        <div className={`Popup_items likes like_neg`}>
                            <Like/>
                            <span>{neg}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};

export default PopupItem;
