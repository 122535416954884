import React from 'react';
import './AboutProject.css'
import BackBlock from "../element/BackBlock";

const AboutProject = ({lang, setCatCardClick, setData, setDistChoice}) => {

    return (
        <div className={`AboutProject_wrapper`}>
            <div className={`AboutProject_wrap`}>
                <BackBlock lang={lang} link={'/'} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
                <div className={'AboutProject'}>
                    <h2>Добро пожаловать на наш сайт, посвященный улучшению жизни в прекрасном городе Алматы!</h2>
                    <p>Здесь каждый житель города может делиться своими идеями и предложениями, направленными на развитие социально-экономической сферы,
                        улучшение местного управления и другие аспекты городской жизни.</p>

                    <p> Мы стремимся активно включить горожан в формирование будущего Алматы. Наша цель - создать особый ресурс, который объединит лучшие идеи в
                        едином “Банке идей". </p>
                    <p>Здесь каждый ваш голос имеет значение, и ваше предложение может оказать значительное влияние на развитие нашего города.</p>

                    <p>Мы верим в силу сотрудничества и уверены, что только сообща мы сможем сделать Алматы еще лучше. Присоединяйтесь к нашему сообществу,
                        делитесь своими идеями и поддерживайте предложения других жителей. Давайте превратим наш город в место, которым каждый будет
                        гордиться!</p>

                    <p> Наш проект - это не только возможность выразить свое видение, но также площадка для активного общения и обмена мнениями с другими
                        жителями Алматы.</p>

                    <p> Мы предоставляем вам инструменты для демократической инициативы и активного участия. Ваше участие делает наше сообщество сильнее и
                        помогает нам двигаться вперед к созданию лучшего будущего для Алматы.</p>

                    <p>Присоединяйтесь к нашему движению и вместе строим будущее нашего замечательного города. Ваш голос, ваши идеи и ваше участие имеют
                        значение. Давайте вдохновлять друг друга и создавать более благоприятное будущее для Алматы!</p>
                </div>


            </div>
        </div>
    );
};

export default AboutProject;
