import React, {useEffect, useState} from 'react';
import './ConfirmEmail'
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {lanGs} from '../../utils/lang/langConfirmEmail'
import {message} from "antd";
import Cookies from "js-cookie";
const ConfirmEmail = ({lang}) => {
    const [isValue, setIsValue] = useState(null)
    const navigate = useNavigate();
    const {token} = useParams();


    useEffect(() => {
        token && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/auth/email-confirm?token=${token}`,
            data: {},
            headers: {"Content-type": "application/json; charset=UTF-8",},

        })
            .then((res) => {
                setIsValue(true)
                message.success(`${lanGs['Ваш Email успешно подтвержден'][lang]}`, [5]).then(  navigate('/login'))
            })
            .catch(function (error) {
                setIsValue(false)

                if(error.response.data.message === 'TOKEN_ALREADY_CONFIRMED'){
                    // уже подтвержден

                    message.error(`${lanGs['Email уже подтвержден'][lang]}`, [5]).then(  navigate('/'))
                }
                else if(error.response.data.message === 'TOKEN_EXPIRED'){
                    message.error(`${lanGs['t_error'][lang]}`, [5]).then(  navigate('/'))

                }
                else if(error.response.data.message === 'TOKEN_NOT_FOUND'){
                    navigate('*')
                }

            });
    }, [token]);


    return (
        <div className={'ForgotBlock_wrapper'}>
            <div className={'ForgotBlock_wrap'}>
                <div className={'ForgotBlock_title_text_wrap'}>

                    <span className={'ForgotBlock_title_text'}>{lanGs['Подтверждение Email:'][lang]}</span>
                </div>


      <span onClick={() => isValue && navigate('/login')} className={'Auth_btn_link'} style={{background: `${isValue ? 'rgba(0, 238, 4, 0.32)':'gray'}`}}>{lanGs['Авторизоваться'][lang]}</span>

            </div>

        </div>

    )
};

export default ConfirmEmail;
