import React, {useEffect, useState} from "react";

import Map from "./Layer/Map";
import "./Style/Main.css";
import CardsPanel from "./Elements/cardsPanel";
import axios from "axios";


import NavEventButtons from "./Elements/NavEventButtons";
import CardItem from "./Elements/cardItem";

import CategoryCheckB from "./Elements/categoryCheckB";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";



const Main = ({distChoice, lang, toMark, setToMark, dataCat, setDataCat, data, setCatCardClick, setClickInitiative, setData, setMapCatClick, tabBtn, setDistChoice, cardSelect, setCardSelect}) => {
    const [filterStatus, setFilterStatus] = useState(null);

    const [isError, setIsError] = useState(false);
    const [isLoad, setIsLoad] = useState(false);




    const [mapType, setMapType] = useState("cluster");
    const [map, setMap] = useState(null);


    const [cardData, setCardData] = useState(null);
    const [distShape, setDistShape] = useState(null);
    const [statusData, setStatusData] = useState(null);
    const [show, setShow] = useState(true);

    const navigate = useNavigate();
    window.addEventListener('popstate', function(event) {
        setDistChoice({ dist: "г. Алматы", id: 0 })
        navigate(`/`)
    });

    useEffect(() => {
        setCardSelect(null)
        setFilterStatus(null)
        axios.get(`/almaty-mon-repairworks-api/district-shapes/v2`).then(res => {
            setDistShape(res.data);
        });


    }, [distChoice]);

/*    useEffect(() => {

        setFilterStatus(null);
    }, [isCategory, distChoice]);*/

    return (
        <div className={"Main_wrapper"}>

            <Map
                mapType={mapType}
                map={map}
                setMap={setMap}
                data={data}
                setCardSelect={setCardSelect}
                toMark={toMark}
                filterStatus={filterStatus}
                setToMark={setToMark}
                isLoad={isLoad}
                distShape={distShape}
                distChoice={distChoice}
                statusData={statusData}
                lang={lang}
                cardSelect={cardSelect}
                setClickInitiative={setClickInitiative}
                setData={setData}

            /> {/* тело карты*/}
            <div className={"Main_control"}>
                <CategoryCheckB lang={lang} dataCat={dataCat?.categories}  setCardSelect={setCardSelect} setCatCardClick={setCatCardClick} setData={setData} distChoice={distChoice} setMapCatClick={setMapCatClick}/>

            </div>
            {data?.length > 0 && <CardsPanel
                data={data}
                cardSelect={cardSelect}
                setCardSelect={setCardSelect}
                setToMark={setToMark}
                setFilterStatus={setFilterStatus}
                lang={lang}
                setCardData={setCardData}
                cardData={cardData}
                show={show}
                isLoad={isLoad}
                setClickInitiative={setClickInitiative}
                filterStatus={filterStatus}
                tabBtn={tabBtn}
            />}
            <div className={`map_element_items ${show && ("nav_show_" + show)}`}>
                {cardData && cardSelect &&
                    <CardItem
                        cardData={cardData}
                        setToMark={setToMark}
                        lang={lang}
                        cardSelect={cardSelect}
                    />
                }
                {data?.length > 0 && <NavEventButtons
                    setShow={setShow}
                    show={show}
                    cardSelect={cardSelect}
                    setCardSelect={setCardSelect}
                    setToMark={setToMark}
                />}
            </div>
        </div>
    );
};

export default Main;
