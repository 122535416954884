import React, {useEffect, useState} from "react";
import {Button, Cascader, Checkbox, Collapse, Form, Popover, Select, Space, Tooltip} from "antd";
import { lanGs } from "../langData";
import '../Style/categoryCheckB.css'
import Cookies from "js-cookie";
import axios from "axios";
const { Panel } = Collapse;

const CategoryCheckB = ({ dataCat, lang, setCardSelect, setCatCardClick, distChoice, setMapCatClick }) => {

  let [dataW, setDataW] = useState(null)
  const [itemBox, setItemBox] = useState(null);
  const [disabled, setDisabled] = useState(false);


  const [dataWork, setDataWork] = useState(null);
  const [dataObj, setDataObj] = useState(null);
  let ttr = [];

  const [form] = Form.useForm();
  const onChange = (key) => {
  //  setItemBox(key)
  };
  const onReset = () => {
    setItemBox(null)
    setCardSelect(null);


    Cookies.remove('catC');
    setCatCardClick(null)
    setMapCatClick(null)


  };
  const onFinish = (values) => {
    Cookies.set('catC', values?.categoryIds?.toString())
    setMapCatClick(values)
    setCatCardClick(values.categoryIds)

    setCardSelect(null);
  };


  useEffect(() => {



      axios.get(`/sc-initiatives/api/v1/ref/cat-work`).then((res) => {
        setDataWork(res.data);
      })
      axios.get(`/sc-initiatives/api/v1/ref/cat-object`).then((res) => {
        setDataObj(res.data);
      })


  }, []);

  const filter = (inputValue, path) =>
      path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  return (
      dataCat ? <Form form={form} onFinish={onFinish} >
      <div className={'category_text_header'}>
        <span className={'category_text_header_name'}>{lanGs['Категории инициатив'][lang]}</span>
{/*        <Tooltip placement="left" color={"#146eb0"}  title={`Максимальное количество категорий для отображения: 3`}>
        <span className={'category_text_header_value'}>{lanGs['Выбрано '][lang]} {itemBox?.length ? itemBox.length : 0}/3</span>
        </Tooltip>*/}
      </div>

      <Form.Item>
        <Space>
          <Tooltip placement="topLeft" color={"#146eb0"}  title={lanGs[`Показать все`][lang]}>
          <Button htmlType="reset" onClick={onReset} className={'category_reset_btn'}>{lanGs['Показать все'][lang]}</Button>
          </Tooltip>
          <Tooltip placement="topLeft" color={"#146eb0"}  title={lanGs[`Применить фильтры`][lang]}>
          <Button type="primary" htmlType="submit" className={'category_submit_btn'}>{lanGs['Применить'][lang]}</Button>
          </Tooltip>
          </Space>
      </Form.Item>
        <Form.Item label={lanGs["Категория инициативы:"][lang]} name="categoryIds"  className={'category_filter_wrap'} >
          <Select style={{width: '100%',}} size="large" options={dataCat?.map(i => {return {value: i.id, label: i.name[lang]}})} allowClear
                  placeholder={lanGs['Выбрать категорию'][lang]}
          />

        </Form.Item>

        <Form.Item label={lanGs["Категория работ инициативы:"][lang]} name="workId"  className={'category_filter_wrap'}>
          <Select style={{width: '100%',}} options={dataWork?.map(i => {return {value: i.id, label: i.name[lang]}})} size="large" allowClear
                  placeholder={lanGs['Выбрать категорию'][lang]}
          />

        </Form.Item>

        <Form.Item label={lanGs["Категория объекта инициативы:"][lang]} name="objectId"  className={'category_filter_wrap'}>
          {/*<Select style={{width: '100%',}} options={dataObj?.map(i => {return {value: i.id, label: i.name[lang]}})} size="large"/>*/}
          <Cascader
              options={dataObj?.map(i => {return {value: i.id, label: i.name[lang]}}).sort((a, b) => a.label.localeCompare(b.label))}

              placeholder={lanGs['Выбрать категорию'][lang]}
              showSearch={{
                filter,
              }}
              size="large"
          />
        </Form.Item>
      {/*<Form.Item name="check"  className={'list_for_category'} initialValues={dataCat?.filter(sub => sub.amount > 0).map(sub => sub.id)}>
        {dataCat &&   <Checkbox.Group  onChange={onChange} className={'list_for_category_item'}>
          {dataCat?.map(sub => {
            return <Checkbox value={sub.id} disabled={!sub.amount > 0 } key={'sub.id-' + sub.id}>
              <div>
                <span>{sub.name[lang]} </span>
                <span>{sub.amount}</span>
              </div>
            </Checkbox>
          })}
        </Checkbox.Group>}
      </Form.Item>*/}
    </Form>: null
  );

};

export default CategoryCheckB;
