import React, {useEffect, useState} from 'react';
import {Button, Form, Input} from "antd";
import './ForgotBlock.css'
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {lanGs} from '../../utils/lang/langForgotBlock'

const ForgotBlock = ({lang}) => {
    const[isValue, setIsValue]=useState(false)
    const[textMessage, setTextMessage]=useState(``)
    const navigate = useNavigate();

    const onFinish = (values) => {
        values?.email && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/auth/password-reset?email=${values.email}`,
            data: {},
            headers: {"Content-type": "application/json; charset=UTF-8",},
        })
            .then((res) => {
                setIsValue(true)
                setTextMessage(`0`)
            })
            .catch(function (error) {
                setTextMessage(`1`)
                setIsValue(false)
            });
    };
    let textData = (code) => {
        switch (code) {
            case '0':
                return <div>{lanGs['На нижеуказанный Email высланы инструкции для смены пароля, проверьте Вашу почту.'][lang]}</div>
            case '1':
                return <div>{lanGs['Пользователь не найден.'][lang]}<br/>{lanGs['Проверьте email или пройдите'][lang]} <a href="#" onClick={() => navigate('/reg')} style={{fontWeight: 600}}>{lanGs['регистрацию'][lang]}</a></div>
            default: return  <div>{lanGs['Введите E-mail адрес, который Вы указали при регистрации, мы вышлем Вам ссылку на восстановление пароля.'][lang]}</div>

        }
    }
    const validateMessages = {required: '${label} is required!', types: {email: '${label} is not a valid email!',},};
    return (
        <div className={'ForgotBlock_wrapper'}>
            <div className={'ForgotBlock_wrap'}>
                <div className={'ForgotBlock_title_text_wrap'}>
                    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={'ForgotBlock_title_arrow'} onClick={() => navigate('/login')}>
                        <path d="M11.3747 0.166826C12.6247 0.166826 13.458 1.00016 13.458 2.25016C13.458 2.87516 13.2497 3.29183 12.833
                        3.70849L5.54134 11.0002L12.833 18.2918C13.6663 19.1252 13.6663 20.3752 12.833 21.2085C11.9997 22.0418 10.7497
                        22.0418 9.91634 21.2085L1.16634 12.4585C0.333008 11.6252 0.333008 10.3752 1.16634 9.54183L9.91634 0.791828C10.333
                        0.375162 10.958 0.166826 11.3747 0.166826Z" fill="#1F4DC5"/>
                    </svg>
                    <span className={'ForgotBlock_title_text'}>{lanGs['Забыли пароль'][lang]}</span>
                </div>

                <Form name="nest-messages321" onFinish={onFinish} style={{maxWidth: 600,}} validateMessages={validateMessages} layout="vertical" autoComplete="off">
                    <Form.Item noStyle>
                        <span className={'ForgotBlock_explanation'}>{textData(textMessage)}</span>
                    </Form.Item>
                    <Form.Item label={lanGs["Электронная почта"][lang]} name={'email'} rules={[{required: true, type: 'email', message: `${lanGs['Не' +
                        ' заполнено'][lang]}`,},]} className={'RegBlock_line'}>
                        <Input className={'Email_type_input'} size="large" disabled={textMessage === '0'}/>
                    </Form.Item>
                    {!isValue && <Form.Item>
                        <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Отправить'][lang]}</Button>
                    </Form.Item>}
                </Form>
            </div>

        </div>

    )

}


export default ForgotBlock;
