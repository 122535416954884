import React, {useState} from 'react';
import './WorkMapPage.css'
import BackBlock from "../element/BackBlock";
import GisSMR from "../element/WMap/GisSMR";


const WorkMapPage = ({lang, setCatCardClick, setData, setLang}) => {
    const [distChoice, setDistChoice] = useState({ dist: "г. Алматы", id: 0 });
    const [toMark, setToMark] = useState(null);
    return (
        <div className={`WorkMapPage_wrap`}>
            <BackBlock lang={lang} link={'/'} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice} page={false}/>
            <GisSMR lang={lang} setLang={setLang}/>


        </div>
    );
};

export default WorkMapPage;
