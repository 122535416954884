import React, {useEffect, useState} from "react";
import "./feedbackItem.css";
import {lanGs} from "../../../../utils/langData";
import {Button, Checkbox, Form, message, Radio} from "antd";
import axios from "axios";

const FeedbackItem = ({
                          likeData,
                          lang,
                          clickLike,
                          setClickLike,
                          setLikeUpdate,
                          cardId,
                          setOpenLike,
                      }) => {
    const [dataL, setDataL] = useState(null);
    const [optionDef, setOptionDef] = useState("like");
    const [sendForm, setSendForm] = useState(false);
    const [resultLikes, setResultLikes] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {


    }, [likeData]);
    useEffect(() => {

        setDataL(
            likeData
                .filter((i) => i.type === optionDef)
                .map((it) => {
                    return {label: it.name[lang], value: it.id};
                })
        );
    }, [lang, likeData, optionDef]);
    const onFinish = (values) => {
        if (
            values &&
            values.Checkbox_like !== undefined &&
            values.Checkbox_like?.length > 0
        ) {
            setSendForm(true);
            let arr = [];
            axios({
                method: "post",
                url: "/sc-gis/api/v1/repair-work/feedback",
                data: {
                    projectId: cardId,
                    type: optionDef,
                    polls: values.Checkbox_like,
                },
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then((res) => {
                    arr = res.data;
                    setResultLikes(arr);
                    message.success(`${lanGs["Отзыв отправлен"][lang]}`);
                })
                .catch(function (error) {
                    // alert("Произошла ошибка загрузки данных по запросу, данные будут перезагружены")
                    message.success(`${lanGs["Ошибка отправки"][lang]}`);
                });
        } else {
            message.warning(`${lanGs["Не выбран отзыв"][lang]}`);
            setSendForm(null);
        }
    };
    const onReset = () => {
        setOpenLike(false);
        setClickLike(null);
        form.resetFields();
        setSendForm(false);
        setLikeUpdate(cardId);
        setResultLikes(null)
    };

    const onChange = (e) => {
        setOptionDef(e.target.value);
    };

    return dataL && !sendForm ? (
        <div className={"FeedbackItem_wrapper"}>
            <span className={"Title_like_head"}>{lanGs["Вы остались"][lang]}</span>
            <Radio.Group
                onChange={onChange}
                value={optionDef}
                size="large"
                className={"FeedbackItem_radio"}
            >
                <Radio value={"like"}>{lanGs["Довольны"][lang]}</Radio>
                <Radio value={"dislike"}>{lanGs["Недовольны"][lang]}</Radio>
            </Radio.Group>
            {likeData && (
                <Form form={form} name="like_form" onFinish={onFinish} className={'form_likes'}>
          <span className={"FeedbackItem_title2"}>
            {lanGs["Вы можете выбрать несколько"][lang]}{" "}
          </span>
                    <Form.Item name="Checkbox_like" className={"FeedbackItem_list"}>
                        <Checkbox.Group
                            options={dataL && dataL}
                            className={`FeedbackItem_wrap ${optionDef}`}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className={"FeedbackItem_btn"}>
                            <Button htmlType="button" onClick={onReset}>
                                {lanGs["Отменить"][lang]}
                            </Button>
                            <Button type="primary" htmlType="submit" className={"blue_btn"}>
                                {lanGs["Отправить"][lang]}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </div>
    ) : (
        <div className={"FeedbackItem_wrapper"}>
            <div className={"FeedbackItem_result_title"}>
                <span>{lanGs["Что"][lang]}</span>
                {optionDef === "like" ? (
                    <span style={{color: "#19A526"}}>
            {lanGs["понравилось"][lang]}:
          </span>
                ) : (
                    <span style={{color: "#D51E3C"}}>
            {lanGs["не понравилось"][lang]}:
          </span>
                )}
            </div>
            <div className={`FeedbackItem_wrap result ${clickLike}`}>
                {resultLikes &&
                    resultLikes.map((i, index) => {

                        return (
                            <div
                                className={"FeedbackItem_result_item"}
                                key={index + ":" + i.poll.id}
                            >
                                <span>{i.poll.name[lang]}</span>
                                <span className={"FeedbackItem_result_value"}>{i.value}</span>
                            </div>
                        );
                    })}
            </div>
            <div className={"FeedbackItem_to_wrap "}>
        <span className={"FeedbackItem_title"}>
          {lanGs["Вы хотите оставить обращение?"][lang]}
        </span>
                <span className={"FeedbackItem_title2"}>
          {lanGs["Вы будете перенаправлены на сайт"][lang]}
        </span>
            </div>
            <div className={"FeedbackItem_btn"}>
                <Button htmlType="button" onClick={onReset}>
                    {lanGs["Пропустить"][lang]}
                </Button>
                <Button
                    type="primary"
                    className={"blue_btn"}
                    onClick={onReset}
                    href={
                        "https://frontoffice.open-almaty.kz/Client/Cabinet/AppealInstruction"
                    }
                    target={"_blank"}
                >
                    {lanGs["Перейти"][lang]}
                </Button>
            </div>
        </div>
    );
};

export default FeedbackItem;
