import React from "react";
import { Button } from "antd";

const NavEventButtons = ({setShow, show, cardSelect, setCardSelect, setToMark}) => {
  return (
    <div className={'sidebar_arrow_wrap'}>
      <Button onClick={() => setShow(!show)}>
        {show === true ? "<" : ">"}
      </Button>
      {cardSelect && <Button onClick={() => {

        setCardSelect(null);
        setToMark(null)

      }}>X</Button>}
    </div>
  );
};

export default NavEventButtons;
