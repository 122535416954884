import React, {useEffect, useState} from 'react';
import './CardList.css'
import {Button, Cascader, Checkbox, Dropdown, Form, Input, Menu} from "antd";
import CardsItem from "./CardsItem";
import {lanGs} from "../../../../utils/langData";
import {ReactComponent as FilterIcon} from './icons/filter.svg'

const CardList = ({lang, data, setCardSelect, show, cardSelect, setToMark, setIsFilterData, setFilterStatus, isFilterData}) => {
    const [filtered, setFiltered] = useState(null);

    const [formOne] = Form.useForm();
    const [form2] = Form.useForm()
    const [checkedValues, setCheckedValues] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false); // Define showDropdown state variable
    const open = "sidebar ";
    const closed = "sidebar sbclosed";
    const onSearch = (e) => {
        setFiltered(
            data.filter((i) => {
                return (
                    (i.name && i.name[lang].toLowerCase().includes(e.target.value.toLowerCase())) ||
                    (i.address && i.address.toLowerCase().includes(e.target.value.toLowerCase()))
                );
            })
        );
    };


    useEffect(() => {
        isFilterData?.length > 0 ? setFiltered(isFilterData) : setFiltered(data);
    }, [data, isFilterData]);
    const handleApply = (e) => {
        setCardSelect(false);
        formOne.resetFields();
        setFilterStatus(checkedValues?.length > 0 ? checkedValues : null);
        setShowDropdown(false);
    };
    const handleReset = () => {
        setCheckedValues([]);
        setIsFilterData(null);
        // Сбросить выбранные значения
        setCheckedValues([]);

        // Закрыть дропдаун
        setShowDropdown(false);
    };


    const menu = (
        <Menu>
            {uniqueItems(data, "status", "id")
                .map((i) => {
                    return {value: i.id, label: i.name[lang]};
                })
                .map((option) => (
                    <Menu.Item key={option.value + Math.random()}>
                        <Checkbox
                            checked={checkedValues.includes(option.value)}
                            onChange={(e) => {
                                e.stopPropagation(); // Добавлено для предотвращения закрытия дропдауна
                                const value = option.value;
                                setCheckedValues((prevValues) =>
                                    e.target.checked
                                        ? [...prevValues, value]
                                        : prevValues.filter((val) => val !== value)
                                );
                            }}
                        >
                            {option.label}
                        </Checkbox>
                    </Menu.Item>
                ))}
            <Menu.Divider/>
            <Menu.Item>
                <Button type="link" onClick={handleApply}>
                    {lanGs["Применить"][lang]}
                </Button>
                <Button type="link" onClick={handleReset}>
                    {lanGs["Сбросить"][lang]}
                </Button>
            </Menu.Item>
        </Menu>
    );
    useEffect(() => {
        data &&  handleReset()
    }, [data]);
    return (
        <div className={"sidebar_wrapper"}>
            {<div className={`sidebar_items ${show === true ? open : closed}`}>
                {show && <div className={"sidebar_wrap"}>
                    <div className={"sidebar_filter_wrapper"}>
                <span className={"sidebar_filter_search"}>
                  <Form name="basic" form={formOne}>
                    <div className={"LNav_filters search_block"}>
                      <Form.Item>
                        <Input
                            allowClear
                            placeholder={data?.length > 0 && data[0].name && lanGs["Поиск по названию"][lang]}
                            onChange={onSearch}
                        />
                      </Form.Item>
                    </div>
                  </Form>
                </span>
                        <div className={"sidebar_filter_wrap"}>
                            <div className={"sidebar_filter_wrap"}>
                                <div className={"sidebar_filter_filt"}>
                                    {data && data[0]?.status && (
                                        <Form name="sidebar_filter_2">
                                            <div className={"LNav_filters sort_block"}>
                                                <div className={"LNav_filters_Cascader"}>
                            <span className={"iconFilterLayer"}>
                              <Form.Item name={"test2"}>
                                <Dropdown  trigger={["click"]} open={showDropdown} >
                                    <Button>{checkedValues?.length > 0 ? `Выбрано: ${checkedValues?.length}` : lanGs["Статус"][lang]}</Button>
                                </Dropdown>
                              </Form.Item>
                            </span>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </div>
                                <span className={"sidebar_filter_length"}>
                    {`${lanGs["найдено"][lang]}`} {filtered?.length}
                  </span>
                            </div>

                        </div>
                    </div>
                    {data && (
                        <CardsItem
                            itemCount={1000000000}
                            data={filtered ? filtered : data}
                            cardSelect={cardSelect}
                            setCardSelect={setCardSelect}
                            setToMark={setToMark}
                            lang={lang}
                            isFilterData={isFilterData}
                        />
                    )}
                </div>}
            </div>}

        </div>
    );
};

export default CardList;
const uniqueItems = (datas, items, endItem) => {

    return datas.reduce((acc, st) => {
        if (acc.arr[st[items][endItem]]) // если данный объект уже был
            return acc; // ничего не делаем, возвращаем уже собранное
        acc.arr[st[items][endItem]] = true; // помечаем объект, как обработанный
        acc.result.push(st.status); // добавляем объект в массив объектов
        return acc; // возвращаем собранное
    }, {
        arr: {}, // здесь будут отмечаться обработанные объекты
        result: [] // здесь конечный массив объектов
    }).result; // получаем конечный массив объектов
}
