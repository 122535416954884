import React, { useEffect, useState } from "react";
import "../Style/Map.css";
import "leaflet/dist/leaflet.css";
import { GeoJSON, MapContainer, TileLayer, useMap } from "react-leaflet";


import "antd/dist/reset.css";

import LeafletControlGeocoder from "../Elements/searchItem";

import { Spin } from "antd";
import ClusterMapLayer from "./ClusterMapLayer";

import { distCenterSet } from "../../../.../../../utils/helper";

import LegendItem from "../Elements/legendItem";


const Map = ({
  mapType,
  data,
  isLoad,
  toMark,
  setToMark,
  almatyShape,
  distShape,
  distChoice,
  statusData,
  lang,
  setCardSelect,
  cardSelect,
               setClickInitiative,
               filterStatus,
               dataCardPas
}) => {
  const [map, setMap] = useState(null);


  const [distBorder, setDistBorder] = useState();
  const [cityBorder, setCityBorder] = useState();
  /*
   ;*/
  let SetViewOnClick = () => {

    const map = useMap();
    toMark && map.flyTo(toMark, 18);
    useEffect(() => {
      map.eachLayer(function (layer) {
        layer.closePopup();
      });
      let ttr = distCenterSet(distChoice.id.toString());
      toMark && map.flyTo(toMark, 18);

      !toMark && !cardSelect && distChoice && map.flyTo(ttr.center, ttr.zoom);
    }, [toMark, distChoice]);
    useEffect(() => {
      map.closePopup();
      toMark &&
        map.openPopup(

          `${cardSelect?.name}`,
          toMark?.map((i) => i)
        );
    }, [cardSelect]);
  };

  useEffect(() => {
    if (distShape) {
      setCityBorder(
        distShape.map(
          (i) =>
            i.id === 10 && (
              <GeoJSON
                data={{ type: i.type, coordinates: JSON.parse(i.coordinates) }}
                key={"AlmatyKey" + i.id}
              />
            )
        )
      );
      setDistBorder(
        distShape.map(
          (i) =>
            distChoice.id !== 0 &&
            !i.nameRu.includes(distChoice.dist) && (
              <GeoJSON
                data={{ type: i.type, coordinates: JSON.parse(i.coordinates) }}
                key={"DistKey" + i.id}
              />
            )
        )
      );
    }
  }, [distShape]);

  return (
    <div className={"Map_wrapper"}>
     {/* {!data && <Spin tip={lanGs['Загрузка'][lang]} size="large">
        <div className="content" />
      </Spin>}*/}
      <MapContainer
        style={{ width: "100%", height: "100%" }}
        className="markercluster-map"
        id="mapMain"
        // center={toMark ? toMark : center}
        center={ [43.21123, 76.949821]}
        // zoom={INITIAL_MAP_CONFIG.zoom}
        zoom={12}
      /*  minZoom={12}*/
        zoomControl={true}
        scrollWheelZoom={true}
        fadeAnimation={true}
        markerZoomAnimation={true}
        whenCreated={setMap}
        fullscreenControl={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SetViewOnClick coords={toMark} />
        <LeafletControlGeocoder data={data} />


        {distBorder && mapType === "cluster" && distBorder}
        {cityBorder && mapType === "cluster" && cityBorder}



        {/* в coords указать ключ хранения координат */}


          <ClusterMapLayer
            data={filterStatus ? data?.filter(i => i.status.id === filterStatus): data}
            type={mapType}
            statusData={statusData}
            lang={lang}
            setCardSelect={setCardSelect}
            setToMark={setToMark}
            setClickInitiative={setClickInitiative}
          />

      </MapContainer>
      {data && mapType === "cluster" && (
        <LegendItem data={data} lang={lang} setCardSelect={setCardSelect} />
      )}
    </div>
  );
};

export default Map;
