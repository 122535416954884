import React, {useEffect} from "react";
import "./HeaderBlock.css";

import Logo2 from "../assets/logo/gerb.png";
import LogoCRA from "../assets/logo/LogoCRA.png";
import LogoDC from "../assets/logo/DC.png";
import {lanGs} from "../utils/lang/langHeaderBlock";
import {useNavigate} from "react-router-dom";

import Logo3 from "../assets/logo/apple.png";
import Logo1 from "../assets/logo/aple.png";
import Cookies from "js-cookie";
import {Dropdown, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";

const HeaderBlock = ({lang, setLang}) => {

    const name = Cookies.get('name');
    const email = Cookies.get('email');
    const token = Cookies.get('jwt');

    const navigate = useNavigate();

    let items = [
        {
            label: <span onClick={() => {

                Cookies.remove('jwt');
                Cookies.remove('token');
                Cookies.remove('name');
                Cookies.remove('email');
                Cookies.remove('roles');
                navigate('/')
            }}>{lanGs["Выход"][lang]} </span>,
            key: '0',
        }
    ]
    return (
        <div className={`HeaderBlock_wrap`}>
            <div className={`HeaderBlock_item home_icon`} title={"Сервис предложения инициатив"} onClick={() => navigate('/')}>
                <img src={LogoDC} alt="лого сервиса предложения инициатив"/>
                <div className={"home_icon_text"}>
                    <span>{lanGs["Сервис предложения"][lang]}</span>
                    <span>{lanGs["инициатив"][lang]}</span>
                </div>
            </div>
            <a href={"https://opendata.smartalmaty.kz/"} target={"_blank"} className={`HeaderBlock_item `} title={"Ситуационный центр города Алматы"}>
                <img src={LogoCRA} alt="лого ситуационного центра города алматы"/>
                <div className={"home_icon_text"}>
                    <span>{lanGs["Ситуационный"][lang]}</span>
                    <span>{lanGs["центр"][lang]}</span>
                </div>
            </a>
            <a href={"https://almatydc.kz/"} target={"_blank"} className={`HeaderBlock_item `} title={"Центр развития города алматы"}>
                <img src={Logo1} alt="лого центр развития города алматы" className={'top_pad'}/>
                <div className={"home_icon_text"}>
                    <span>{lanGs["Центр развития"][lang]}</span>
                    <span>{lanGs["города алматы"][lang]}</span>
                </div>
            </a>

            <a href={"https://www.gov.kz/memleket/entities/almaty?lang=ru"} target={"_blank"} className={`HeaderBlock_item `}
               title={lanGs["Акимат города Алматы"][lang]}>
                <img src={Logo2} alt="лого Акимат города Алматы" className={'top_pad'}/>
                <span className={"Header_logo_text"}>
                    {lanGs["Акимат города Алматы"][lang].slice(0, 6)} <br/>{" "}
                    {lanGs["Акимат города Алматы"][lang].slice(6)}
                </span>
            </a>
            <a href={"https://digital-almaty.kz/ru"} target={"_blank"} className={`HeaderBlock_item`}
               title={lanGs["Управление Цифровизации города Алматы"][lang]}>
                <img src={Logo3} alt="лого Управление Цифровизации города Алматы" className={'top_pad'}/>
                <div className={`cifra_logo_text`}>
                    <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(0, 13)}</span>
                    <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(13, 25)}</span>
                    <span>{lanGs["Управление Цифровизации города Алматы"][lang].slice(25)}</span>
                </div>
            </a>
            {/*

            <button onClick={() => navigate('/login')}>LOGIN</button>
            <button onClick={() => navigate('/reg')}>REG</button>*/}


            {token ? <div className={"inner_text"}>
                <Dropdown
                    menu={{
                        items
                    }}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <div className={'inner_text_log'}>
                                <span>{name} </span>
                                {/*<span>{email}</span>*/}
                            </div>

                        </Space>

                    </a>
                </Dropdown>

            </div> : <div className={"inner_text"} onClick={() => navigate('/login')}>{lanGs['Вход'][lang]}</div>}
            <a href="https://forms.gle/uAdQWfjJuySpGQjh9" target={"_blank"} className={"Contact_block_item"}>{lanGs['Обратная связь'][lang]}</a>

            <div className={"lang_block"}>
                <span onClick={() => {
                    setLang('nameRu')
                    Cookies.set('lang', 'nameRu')
                }} className={`lang_btn_it ${lang === 'nameRu' ? 'active' : ''}`}>РУС</span>
                <span onClick={() => {
                    setLang('nameKz')
                    Cookies.set('lang', 'nameKz')
                }} className={`lang_btn_it ${lang !== 'nameRu' ? 'active' : ''}`}>ҚАЗ</span>
            </div>

        </div>
    );
};

export default HeaderBlock;
