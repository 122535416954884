export const lanGs = {
    'Назад': {
        nameRu: "Назад",
        nameKz: "Артқа",
    },

}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
