import React, {useState} from 'react';
import './CardsItem.css'
import {Tooltip} from "antd";
import {lanGs} from "../../../../utils/langData";
import DEF from "./icons/def.svg";
import {ReactComponent as Mark} from "./icons/mark.svg";
import {ReactComponent as Address} from "./icons/address.svg";
import {FixedSizeList} from "react-window";

const CardsItem = ({data, cardSelect, setCardSelect, lang, setToMark, isFilterData}) => {

    const Row = ({data, style, index}) => (
        <div className={"sbcontent card_item_wrapper"} key={index} style={style}>
            {data[index]}
        </div>
    );
    const componentItems = data?.map(i => {
        let {name, category, subCategory, address,district, status, id} = i;
        let names = name ? name : address
        return (
            <div
                className={`card_item_wrap ${cardSelect?.id === id ? 'active' : ''}`}
                onClick={() => {
                    setToMark(null);
                    setCardSelect(i)
                }} key={id}>
                {names ?
                    <Tooltip placement="bottom" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'} title={`${names}`}>
                            <span className={'card_item first'}>
                        {names}</span>
                    </Tooltip> :
                    <span className={'card_item first'}>{lanGs['Не указано'][lang]}</span>}
                {subCategory &&
                    <Tooltip
                        placement="bottom"
                        color={'#146eb0'}
                        overlayClassName={'Tooltip_wrapper'}
                        title={subCategory && category?.name[lang] + ' / ' + subCategory.name[lang]
                        }>
                    <span className={'card_item second swap_text_one_Line'}>
                        {subCategory && category?.name[lang] + ' / ' + subCategory.name[lang]}
                    </span>
                    </Tooltip>
                }
                <span className={'card_item third'}>
                        <svg width="50" height="50" viewBox="0 0 45 55" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="22.5" cy="22.5" r="20" stroke={`${status?.color ? `${status?.color}` : '#005797'}`} strokeWidth="2"/>
                            <image x="8.5" y="7" width="28" height="28" href={`${subCategory?.icon ? subCategory.icon : DEF}`}/>
                        </svg>
                    </span>
                {
                    <Tooltip
                        placement="bottom"
                        color={'#146eb0'}
                        overlayClassName={'Tooltip_wrapper'}
                        title={`${lanGs['Район'][lang]}: ${district.name ? district?.name[lang] : lanGs['Не указан'][lang]}`}>
                        <div className={'card_item forth'}>
                            <span><Mark/></span>
                            <span>{district ? district?.name[lang] : lanGs['Не указан'][lang]}</span>
                        </div>
                    </Tooltip>
                }

                {address &&
                    <Tooltip
                        placement="bottom"
                        color={'#146eb0'}
                        overlayClassName={'Tooltip_wrapper'}
                        title={`${lanGs['Адрес объекта'][lang]}: ${names}`}>
                        <div className={'card_item address'}>
                            <span><Address/></span>
                            <span>{names ? names : lanGs['Не указан'][lang]}</span>
                        </div>
                    </Tooltip>

                }
                <div className={'sbcontent_item status '}>
                    <div className={'style_status_dot'}><span style={{color: `${status?.color}`}}>⬤</span> <span>{lanGs['Статус работ'][lang]}:</span>
                    </div>
                    {status && <span
                        style={{color: `${status.color}`}}>{status?.name ? status.name[lang] : lanGs["Не указан"][lang]}</span>}
                </div>

            </div>
        );
    });
    return (<FixedSizeList
            height={window.innerHeight - 210}
            // width={330}
            itemCount={componentItems?.length}
            itemSize={135}
            itemData={componentItems}
        >
            {Row}
        </FixedSizeList>
    );
};

export default CardsItem;
