import React, {useState} from 'react';
import {Button, Form, Input, message, Modal} from "antd";
import './ResolutionBlock.css'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {lanGs} from '../../utils/lang/langResolutionBlock'
import axios from "axios";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";


const ResolutionBlock = ({setIsModalOpen, lang, dataCardPas, setResetTable, tabsKey, setClickInitiative,setDataCardPas}) => {
    const [isModalOpenRes, setIsModalOpenRes] = useState(false);
    const [isModal, setIsModal] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const token = Cookies.get('jwt')

    const onFinish = (values) => {

        isModal && dataCardPas?.id && axios({
            method: "put",
            url: "/sc-initiatives/api/v1/initiative/status-update",
            data: {
                "initiativeId": dataCardPas.id,
                "statusId": parseInt(isModal),
                "comment": values.status

            },
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
            /* cancelToken: source.token,*/
        })
            .then((res) => {
                setResetTable(true)
                setClickInitiative(null)
                setDataCardPas(null)
                success(isModal, lang).then()
                /* messageApi.open({
                     type: 'success',
                     content: `${lanGs['USER_REGISTER_SUCCESS'][lang]}`,
                     duration: 5,
                 });*/
                 // setTimeout(() => navigate('/login'), 3000)
            })
            .catch(function (error) {
                if(error.request.statusText === 'Unauthorized' ){
                    messageApi.open({
                        type: 'error',
                        content: `${lanGs['Unauthorized'][lang]}`,
                        duration: 5,
                    }, );
                    setTimeout(function() {
                        setResetTable(true)
                        setClickInitiative(null)
                        setDataCardPas(null)
                        Cookies.remove('jwt')
                        navigate('/login?redirect=/initiative-moder')
                    }, 1000)
                }

            });
        setIsModalOpenRes(false)

        form.resetFields()
        setIsModal(null)
        setIsModalOpen(false)

    };

     const success = (isModal) => {

        switch (isModal) {
            case '1':
                return  messageApi.open({
                    type: 'success',
                    content: lanGs['Инициатива успешно направлена на голосование'][lang],
                    duration: 5,
                });
            case '2':
                return  messageApi.open({
                    type: 'warning',
                    content: lanGs['Ваш комментарий направлен на почту автора инициативы'][lang] ,
                    duration: 5,
                });
            case '3':
                return  messageApi.open({
                    type: 'error',
                    content: lanGs['Отказ направлен на почту автора инициативы'][lang],
                    duration: 5,
                });
            case '4':
                return  messageApi.open({
                    type: 'success',
                    content: lanGs['Инициатива успешно перенесена в список для акимата'][lang],
                    duration: 5,
                });
            default: break
        }
        // setTimeout(function() {alert('tick')}, 100)
    };
    let TextM = (item) => {
        switch (item) {
            case '1':
                return <div className={'ResolutionBlock_modal'}>
                    <span className={'ResolutionBlock_modal_title_text'}>{lanGs['Подтвердите отправку инициативы на согласование'][lang]}</span>
                    <Button htmlType="submit">{lanGs['Отправить'][lang]}</Button>
                </div>

            case '2':
                return <div className={'ResolutionBlock_modal'}>
                    <span className={'ResolutionBlock_modal_title_text'}>{lanGs['На доработку'][lang]}</span>
                    <span className={'ResolutionBlock_modal_warning_text'}><ExclamationCircleOutlined />{lanGs['Комментарий (причина возврата на доработку)'][lang]} </span>
                    <Form.Item name={'status'}  rules={[{required: true, message: lanGs['Обязательное поле'][lang],},]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Button htmlType="submit">{lanGs['Отправить'][lang]}</Button>
                </div>

            case '3':
                return <div className={'ResolutionBlock_modal'}>
                    <span className={'ResolutionBlock_modal_title_text'}>{lanGs['Отказ'][lang]}</span>
                    <span className={'ResolutionBlock_modal_warning_text'}><ExclamationCircleOutlined /> {lanGs['Комментарий (причина отмены)'][lang]}</span>
                    <Form.Item name={'status'} rules={[{required: true, message: lanGs['Обязательное поле'][lang],},]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Button htmlType="submit">{lanGs['Отправить'][lang]}</Button>
            </div>
            case '4':
                return <div className={'ResolutionBlock_modal'}>
                    <span className={'ResolutionBlock_modal_title_text'}>{lanGs['Подтвердите отправку инициативы в акимат'][lang]}</span>
                    <Button htmlType="submit">{lanGs['Отправить'][lang]}</Button>
                </div>
            default:break
        }
    }
    const handleOk = () => {
        setIsModalOpenRes(false);
    };
    const handleCancel = () => {
        setIsModalOpenRes(false);
    };
    return (
        <div className={'ResolutionBlock_wrap'}>
            {contextHolder}
            {tabsKey !== '1' && <Button className={'color_btn_success'} onClick={() => {
                setIsModal('1');
                setIsModalOpenRes(true)
            }}>
                {lanGs['На голосование'][lang]}
            </Button>}

            {tabsKey !== '2' && <Button className={'color_btn_revision'} onClick={() => {
                setIsModal('2');
                setIsModalOpenRes(true)
            }}>
                {lanGs['На доработку'][lang]}
            </Button>}

            {tabsKey !== '3' && <Button className={'color_btn_fault'} onClick={() => {
                setIsModal('3');
                setIsModalOpenRes(true)
            }}>
                {lanGs['Отказ'][lang]}
            </Button>}
            {tabsKey === '1' && <Button className={'color_btn_send'} onClick={() => {
                setIsModal('4');
                setIsModalOpenRes(true)
            }}>
                {lanGs['Одобрен'][lang]}
            </Button>}

            <Modal open={isModalOpenRes} onOk={handleOk} onCancel={handleCancel} centered className={''} footer={false}>
                <Form name="validate_other" onFinish={onFinish} form={form} >
                    {TextM(isModal)}
                </Form>
            </Modal>
        </div>
    );
};

export default ResolutionBlock;
