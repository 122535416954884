export const lanGs = {
    'Новые': {
        nameRu: "Новые",
        nameKz: "Жаңа",
    },
    'На доработке': {
        nameRu: "На доработке",
        nameKz: "Пысықтауда",
    },
    'Отказ': {
        nameRu: "Отказ",
        nameKz: "Бас тарту",
    },
    'На голосовании': {
        nameRu: "На голосовании",
        nameKz: "Дауыс беруде",
    },'В архиве': {
        nameRu: "В архиве",
        nameKz: "Мұрағатта",
    },'Одобрен': {
        nameRu: "Одобрен",
        nameKz: "Мақұлданды",
    },'Выбрать год': {
        nameRu: "Выбрать год",
        nameKz: "Жылды таңдаңыз",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
