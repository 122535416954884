import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Tooltip} from "antd";
import {lanGs} from "../../../../utils/langData";

import {ReactComponent as Mark} from './icons/mark.svg'
import {ReactComponent as Address} from './icons/address.svg'
import {ReactComponent as Fio} from './icons/fio.svg'
import {ReactComponent as Comp} from './icons/companu.svg'
import {ReactComponent as Tel} from './icons/tel.svg'
import {ReactComponent as Mail} from './icons/mail.svg'
import {ReactComponent as Clock} from './icons/clok.svg'
import {ReactComponent as Like} from "./icons/like.svg";
import {ReactComponent as Review} from "./icons/review.svg";
import DEF from "./icons/def.svg";
import defImg from "./icons/degImg.png";
import {ExclamationCircleFilled} from "@ant-design/icons";
import axios from "axios";

const CardPassport = ({setToMark, cardData, lang, setOpenLike, setLikeUpdate}) => {
    let {
        name,
        category,
        subCategory,
        address,
        marker,
        department,
        contractor,
        description,
        district,
        status,
        startDate,
        endDate,
        neg,
        pos
    } = cardData;
    let names = name ? name : address
    const {confirm} = Modal;
    // lang = 'nameKz'

    const showConfirm = (event) => {
        confirm({
            title: lanGs["Отправить вашу оценку?"][lang],
            icon: <ExclamationCircleFilled/>,
            okText: `${lanGs["Отправить"][lang]}`,
            cancelText: `${lanGs["Отменить"][lang]}`,
            onOk() {
                isLikes(event);
            },
            onCancel() {
            },
        });
    };
    let isLikes = (item) => {
        axios({
            method: "post",
            url: "/sc-gis/api/v1/repair-work/feedback",
            data: {
                projectId: item.id,
                type: item.type,
                polls: [],
            },
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => {
                setLikeUpdate(item.id);
                message.success(`${lanGs["Оценка отправлена"][lang]}`);
            })
            .catch(function (error) {
                message.success(`${lanGs["Ошибка отправки"][lang]}`);
            });
    };
    return (
        <div className={"sbcontent_wrap"}>

            {/*превью / дефолтная картинка / галерея */}
            <div className={"sbcontent_item_gallery"} style={{backgroundImage: `url(${subCategory?.image ? subCategory?.image : defImg})`}}>
                <span/>
            </div>

            {/*строка с названием и кнопкой приблизить*/}
            <div className={"sbcontent_item btn_line name_block"}>
                <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={`${names}`}>
                    <span className={"short_name_2line"}>
                        {names}
                    </span>
                </Tooltip>
                <Tooltip placement="right" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"}
                         title={`${marker ? `${lanGs["Приблизить"][lang]}` : `${lanGs["Нет метки"][lang]}`}`}
                >
                    <Button type="primary" icon={<Mark/>} className={`${marker ? "mark_active" : "mark_dis"}`} onClick={() => {
                        setToMark(null)

                        marker && setToMark(cardData);

                    }}/>
                </Tooltip>
            </div>
            {/*навигация табов и лайки*/}
            {cardData.pos?.toString() && (
                <div className={"sbcontent_tabs_like_wrap "}>
                    <div className={"sbcontent_tabs_like"}>
                        {cardData?.neg?.toString() && cardData.pos?.toString() ? (
                            <Tooltip
                                placement="top"
                                color={"#146eb0"}
                                overlayClassName={"Tooltip_wrapper"}
                                title={`${lanGs["Оставить отзыв"][lang]}!`}
                            >
                                <div className={"card_items_likes"}>
                                    <div
                                        className={"card_items_pos_text "}
                                        onClick={() => {
                                            setOpenLike(false);
                                            setOpenLike(true);
                                        }}
                                    >
                                        {/*{`${lanGs["Оставить отзыв"][lang]}!`}*/}
                                        <Review/>
                                    </div>
                                </div>
                            </Tooltip>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className={"sbcontent_tabs_like"}>
                        {cardData?.neg?.toString() && cardData.pos?.toString() ? (
                            <div className={"card_items_likes "}>
                                <Tooltip
                                    placement="top"
                                    color={"#146eb0"}
                                    overlayClassName={"Tooltip_wrapper"}
                                    title={`${lanGs["Положительный отклик"][lang]}`}
                                >
                                    <div className={"card_items_pos like"} onClick={() => {
                                        showConfirm({type: "like", id: cardData.id});
                                    }}>
                                        <Like/>
                                        <span>{cardData.pos}</span>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    color={"#146eb0"}
                                    overlayClassName={"Tooltip_wrapper"}
                                    title={`${lanGs["Отрицательный отклик"][lang]}`}
                                >
                                    <div className={"card_items_neg"} onClick={() => {
                                        showConfirm({type: "dislike", id: cardData.id});
                                    }}>
                                        <Like/>
                                        <span>{cardData.neg}</span>
                                    </div>
                                </Tooltip>
                            </div>
                        ) : (
                            <div/>
                        )}
                    </div>
                </div>
            )}

            {/*категория*/}
            {subCategory && <div className={"sbcontent_items category"}>
                {subCategory &&
                    <Tooltip placement="bottom" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"}>
                        <div className={"card_item category "}>
                            <img src={`${subCategory?.icon ? subCategory.icon : DEF}`} alt={"иконка категории"}/>
                            {(category || subCategory) ?
                                <div className={"category_wrap_line"}>
                                    <span>{category?.name[lang]} {subCategory ? '/ ' : ''}</span>
                                    {subCategory && <span> {subCategory.name[lang]}</span>}
                                </div> : lanGs["Не указан"][lang]}
                        </div>
                    </Tooltip>}
            </div>}

            {/*Расположение: Район / Адрес */}
            <div className={'sbcontent_items'}>
                <span className={'sbcontent_title'}>{lanGs['Расположение'][lang]}</span>
                <div className={'sbcontent_item text_data dist_line'}>
                    <span><Mark/> {lanGs['Район'][lang] + ': '}</span>
                    {district ?
                        <span className={"sbcontent_value"}>{district?.id === 0 ? district.name[lang] : district.name[lang].slice(0, -6)}</span> :
                        <span className={"sbcontent_value"}>{lanGs['Не указан'][lang]}</span>}
                </div>
                <div className={'sbcontent_item text_data address_line'}>
                    <span><Address/> {lanGs['Адрес'][lang] + ': '}</span>
                    {address ? <Tooltip placement="topRight" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'} title={`${address}`}>
                        <span className={"sbcontent_value address"}>{address}</span>
                    </Tooltip> : <span className={"sbcontent_value"}>{lanGs['Не указан'][lang]}</span>}
                </div>
            </div>

            {/*Сроки : даты / статус* */}
            {(status || startDate || endDate) &&
                <div className={'sbcontent_items'}>
                    <span className={'sbcontent_title'}>{lanGs['Сроки и статус'][lang]}</span>
                    <div className={'sbcontent_item text_data dist_line'}>
                        <span><Clock/> {lanGs['Сроки'][lang] + ': '}</span>
                        {(startDate || endDate) &&
                            <span className={"sbcontent_value"}>{startDate ? startDate : ''} - {endDate ? endDate : ''}</span>}
                    </div>
                    {subCategory?.id !== 96  && <div className={'sbcontent_item text_data '}>
                        <div className={'style_status_dot'}>
                            <span style={{color: `${status?.color}`}}>⬤</span>
                            <span>{lanGs['Статус'][lang]}:</span>
                        </div>
                        {status &&
                            <span style={{color: `${status.color}`}} className={"sbcontent_value"}>
                                {status?.name ? status.name[lang] : lanGs["Не указан"][lang]}
                            </span>}
                    </div>}
                </div>}
            {/*Контакты: телефон / почта* */}

            {/*Ответственные: Куратор / Подрядчик* */}
            {(department || contractor) && (
                <div className={"sbcontent_items"}>
                    <span className={"sbcontent_title"}>{lanGs["Ответственные"][lang]}</span>
                    <div className={"sbcontent_item  text_data dist_line"}>
                        <span><Fio/> {lanGs["Куратор"][lang] + ": "}</span>
                        <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={department.name[lang]}>
                            <span className={"sbcontent_value"}>{department.shortName[lang]}</span>
                        </Tooltip>
                    </div>
                    {contractor &&
                        <div className={"sbcontent_item  text_data address_line"}>
                            <span>
                                <Comp/> {lanGs["Подрядчик"][lang] + ": "}
                            </span>
                            <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={contractor.name[lang]}>
                                <span className={"sbcontent_value"}>{contractor.name[lang]}</span>
                            </Tooltip>
                        </div>}
                </div>
            )}

            {/*Подрядчик: фио / телефон* */}
            {(contractor?.supervisor || contractor?.phone || contractor?.email) && (
                <div className={"sbcontent_items"}>
                    <span className={"sbcontent_title"}>{lanGs["Информация о подрядчике"][lang]}</span>
                    {contractor.supervisor && (
                        <div className={"sbcontent_item  text_data dist_line"}>
                            <span><Fio/> {lanGs["ФИО"][lang] + ": "}</span>
                            <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={`${contractor.supervisor}`}>
                                <span className={"sbcontent_value"}>{contractor.supervisor}</span>
                            </Tooltip>
                        </div>
                    )}
                    {contractor.phone && (
                        <div className={"sbcontent_item  text_data address_line"}>
                            <span><Tel/> {lanGs["Телефон"][lang] + ": "}</span>
                            <span className={"sbcontent_value"}>{contractor.phone}</span>
                        </div>
                    )}
                    {contractor?.email?.length && <div className={"sbcontent_item  text_data address_line"}>
                        <span><Mail/> {lanGs["Почта"][lang] + ": "}</span>
                        <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={`${lanGs["Электронный адрес"][lang]}`}>
                            <span className={"sbcontent_value"}>{contractor.email}</span>
                        </Tooltip>
                    </div>}
                </div>
            )}


            {/*Описание*/}
            {description && description.length > 0 && <div className={'sbcontent_items'}>
                <span className={'sbcontent_title'}>{lanGs['Описание'][lang]}</span>
                <div className={'sbcontent_item descriptio_line'}>{description}</div>
            </div>}
        </div>
    );

}

export default CardPassport;
