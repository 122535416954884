import React, { useEffect, useState } from 'react';
import './legendItem.css';

const LegendItem = ({ data, lang }) => {
    const [legData, setLegData] = useState([]);

    useEffect(() => {
        if (data && lang) {
            const aggregatedData = data.reduce((acc, item) => {
                const { id, color, name } = item.status;
                if (!acc[id]) {
                    acc[id] = { name: name[lang], value: 0, color: color };
                }
                acc[id].value += 1;
                return acc;
            }, {});

            setLegData(Object.values(aggregatedData));
        }
    }, [data, lang]);

    return legData.length > 0 ? (
        <div className={'LMap_legend def_bauble_info'}>
            {legData.map((item, index) => (
                <div className={'LMap_legend_color_line'} key={index + ':' + item.color}>
                    <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 3H13.5" stroke={item.color} strokeWidth="5" strokeDasharray="2 2"/>
                    </svg>
                    <span>{item.name} ({item.value})</span>
                </div>
            ))}
        </div>
    ) : null;
};

export default LegendItem;
