export const lanGs = {
    'ТОП-10 инициатив с наибольшим числом голосов': {
        nameRu: "ТОП-10 инициатив с наибольшим числом голосов",
        nameKz: "Ең көп дауыс жинаған үздік 10 бастама",
    },

    'голос': {
        nameRu: "голос",
        nameKz: "дауыс",
    },
    'голоса': {
        nameRu: "голоса",
        nameKz: "дауыстар",
    },
    'голосов': {
        nameRu: "голосов",
        nameKz: "дауыстар",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
