import React from 'react';
import './FooterBlock.css'
import {lanGs} from '../utils/lang/lanfFooterBlock'
const FooterBlock = ({lang}) => {
    return (
        <div className={'FooterBlock_wrap'}>
            <span>{lanGs['footerText'][lang]}</span>
        </div>
    );
};

export default FooterBlock;
