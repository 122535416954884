export const lanGs = {
    'Пароли не совпадают': {
        nameRu: "Пароли не совпадают",
        nameKz: "Құпия сөздер бірдей емес",
    },
    'Пароль успешно изменен': {
        nameRu: "Пароль успешно изменен",
        nameKz: "Құпия сөз сәтті өзгертілді",
    },
    'Смена пароля': {
        nameRu: "Смена пароля",
        nameKz: "Құпия сөзді өзгерту",
    },
    'Требования к паролю:': {
        nameRu: "Требования к паролю:",
        nameKz: "Парольге қойылатын талаптар:",
    },
    'не менее 6 символов': {
        nameRu: "не менее 6 символов",
        nameKz: "кемінде 6 таңба",
    },
    'содержать цифры': {
        nameRu: "содержать цифры",
        nameKz: "сандарды қамтиды",
    },
    'содержать спецсимвол': {
        nameRu: "содержать спецсимвол",
        nameKz: "арнайы таңбадан тұрады",
    },
    'буквы в нижнем регистре': {
        nameRu: "буквы в нижнем регистре",
        nameKz: "кіші әріптер",
    },
    'буквы в верхнем регистре': {
        nameRu: "буквы в верхнем регистре",
        nameKz: "бас әріптер",
    },
    'Новый пароль': {
        nameRu: "Новый пароль",
        nameKz: "Жаңа құпия сөз",
    },
    'не соответствует требованиям': {
        nameRu: "не соответствует требованиям",
        nameKz: "талаптарға сәйкес келмейді",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Отмена': {
        nameRu: "Отмена",
        nameKz: "Бас тарту",
    },
    'Подтвердить': {
        nameRu: "Подтвердить",
        nameKz: "Растау",
    },
    'Успешная смена пароля.': {
        nameRu: "Успешная смена пароля.",
        nameKz: "Құпия сөзді сәтті өзгерту.",
    },
    'Используйте новый пароль для авторизации': {
        nameRu: "Используйте новый пароль для авторизации",
        nameKz: "Авторизациялау үшін жаңа құпия сөзді пайдаланыңыз",
    },
    'Войти': {
        nameRu: "Войти",
        nameKz: "Кіру",
    },
    'Что то пошло не так, попробуйте позже или обратитесь к администратору': {
        nameRu: "Что то пошло не так, попробуйте позже или обратитесь к администратору",
        nameKz: "Бірдеңе дұрыс болмады, кейінірек көріңіз немесе әкімшіге хабарласыңыз",
    },
    'На главную': {
        nameRu: "На главную",
        nameKz: "Басты бетке",
    },'Подтвердите пароль': {
        nameRu: "Подтвердите пароль",
        nameKz: "Құпия сөзді растаңыз",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
