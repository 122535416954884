import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Tooltip} from "antd";
import './ChangePasBlock.css'
import {InfoCircleOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {lanGs} from "../../utils/lang/langChangePasBlock";

const ChangePasBlock = ({lang}) => {
    const [form] = Form.useForm();
    const [passwordMismatch, setPasswordMismatch] = useState(true);
    const [success, setSuccess] = useState('0');

    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        setSuccess('0')
    }, []);
    const navigate = useNavigate();
    const {token} = useParams();
    const validatePasswordConfirmation = (_, value) => {
        const passwordFieldValue = form.getFieldValue('password');
        if (value && passwordFieldValue !== value) {
            setPasswordMismatch(true);
            return Promise.reject(new Error(lanGs['Пароли не совпадают'][lang]));
        } else {
            setPasswordMismatch(false);

            return Promise.resolve();
        }
    };
    const successText = () => {
        messageApi.open({
            type: 'success',
            content: lanGs['Пароль успешно изменен'][lang],
            duration: 10,

        });

    };
    const onFinish = (values) => {

        values && token && axios({
            method: "put",
            url: "/sc-initiatives/api/v1/auth/password-change",
            data: {
                "token": token,
                "password": values.passwordConfirmation,
            },
            headers: {"Content-type": "application/json; charset=UTF-8",},

        })
            .then((res) => {
                Cookies.set('token', res.data.token)
                Cookies.set('name', res.data.name);
                Cookies.set('email', res.data.email);
                Cookies.set('roles', res.data.roles);
                setSuccess('1')
                successText(true)
                // navigate('/')


            })
            .catch(function (error) {
                setSuccess('2')

                // openNotification('top', error.response.data)
                // openNotification(error.response.data)
            });
        // successText()
        // form.resetFields()


    };
    return (
        <div className={'ChangePasBlock_wrapper'}>
            <div className={'ChangePasBlock_wrap'}>
                <div className={'ChangePasBlock_title_text_wrap'}>
                    <span className={'ChangePasBlock_title_text'}>{lanGs['Смена пароля'][lang]}</span>
                </div>
                {success === '0' && <Form
                    form={form}
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{maxWidth: 600,}}
                    layout="vertical" autoComplete="off"
                >
                    <Form.Item
                        hasFeedback
                        label={
                            <Tooltip
                                placement="topRight"
                                color={'#146eb0'}
                                overlayClassName={'Tooltip_wrapper'}
                                title={
                                    <div className={'tooltip_text_form_notif_wrap'}>
                                        <span className={'tooltip_text_form_notif'}>{lanGs['Требования к паролю:'][lang]}</span>
                                        <ul>
                                            <li>{lanGs['не менее 6 символов'][lang]}</li>
                                            <li>{lanGs['содержать цифры'][lang]}</li>
                                            <li>{lanGs['содержать спецсимвол'][lang]}</li>
                                            <li>{lanGs['буквы в нижнем регистре'][lang]}</li>
                                            <li>{lanGs['буквы в верхнем регистре'][lang]}</li>
                                        </ul>
                                    </div>
                                }>{lanGs['Новый пароль'][lang]}<InfoCircleOutlined/>
                            </Tooltip>
                        }
                        name="password"
                        rules={[{required: true, message: `${lanGs['Не заполнено'][lang]}`},
                            {pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g, message: `${lanGs['не соответствует требованиям'][lang]}`,},
                        ]} className={'RegBlock_line pass_input'}>
                        <Input.Password size="large" className={'Password_type_input'}/>
                    </Form.Item>
                    <Form.Item
                        hasFeedback
                        label={lanGs['Подтвердите пароль'][lang]}
                        name="passwordConfirmation"
                        rules={[
                            {required: true, message: lanGs['Не заполнено'][lang]},
                            {
                                pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g,
                                message: '',
                            },
                            {validator: validatePasswordConfirmation},
                        ]}
                        className={'RegBlock_line pass_input'}
                    >
                        <Input.Password size="large" className={'Password_type_input'}/>
                    </Form.Item>
                    <Form.Item className={'ChangePasBlock btn_submit_item_wrap'}>
                        <Button className={'btn_submit_item'} onClick={() => navigate('/')}>{lanGs['Отмена'][lang]}</Button>
                        <Button htmlType="submit" className={'btn_submit_item'} disabled={passwordMismatch}>{lanGs['Подтвердить'][lang]}</Button>
                    </Form.Item>

                </Form>  }
                {success === '1' && <div>
                    <span>{lanGs['Успешная смена пароля.'][lang]}<br/>{lanGs['Используйте новый пароль для авторизации'][lang]}</span>
                    <div className={'ChangePasBlock_success'}>
                        {/*<Button className={'btn_submit_item'} onClick={() => navigate('/')}>{lanGs['Отмена'][lang]}</Button>*/}
                        <Button className={'btn_submit_item'} onClick={() => navigate('/login')}>{lanGs['Войти'][lang]}</Button>
                    </div>
                </div>}
                {success === '2' && <div>
                    <span>
                        {lanGs['Что то пошло не так, попробуйте позже или обратитесь к администратору'][lang]}
                    </span>

                        <div className={'ChangePasBlock_success'}>
                            <Button className={'btn_submit_item'} onClick={() => navigate('/')}>{lanGs['На главную'][lang]}</Button>

                        </div>

                </div>}
            </div>
            {contextHolder}
        </div>

    )

}


export default ChangePasBlock;
