import React, {useEffect, useState} from 'react';
import axios from "axios";
import { lanGs } from "../../../../utils/langData";
import './DistrictNav.css'


const DistrictNav = ({lang, distChoice, setDistChoice, setToMark, setData, setCardSelect, distData}) => {

    const [akimInfo, setAkimInfo] = useState(null)
    const [isBlock, setIsBlock] = useState(false)


    useEffect(() => {
        setAkimInfo(distChoice)
    }, [distChoice]);
    return (

        <div className={`DistrictNav_wrap ${isBlock}`}>
            <span className={'DistrictNav_btn'} onClick={() =>setIsBlock(!isBlock)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.0645 0.967773H1.93548C0.870968 0.967773 0 1.83874 0 2.90326C0 3.96777 0.870968 4.83874 1.93548 4.83874H18.0645C19.129 4.83874 20 3.96777 20 2.90326C20 1.83874 19.129 0.967773 18.0645 0.967773Z"
                        fill="white"/>
                    <path
                        d="M18.0645 8.06445H1.93548C0.870968 8.06445 0 8.93542 0 9.99994C0 11.0645 0.870968 11.9354 1.93548 11.9354H18.0645C19.129 11.9354 20 11.0645 20 9.99994C20 8.93542 19.129 8.06445 18.0645 8.06445Z"
                        fill="white"/>
                    <path
                        d="M18.0645 15.1611H1.93548C0.870968 15.1611 0 16.0321 0 17.0966C0 18.1611 0.870968 19.0321 1.93548 19.0321H18.0645C19.129 19.0321 20 18.1611 20 17.0966C20 16.0321 19.129 15.1611 18.0645 15.1611Z"
                        fill="white"/>
                </svg>

            </span>
            {<div className={`DistrictNav_info_wrap ${!isBlock}`}>
                {akimInfo && <div className={`DistrictNav_info_hide `}>
                <span className={'DistrictNav_info_img'} style={{backgroundImage: `url(${akimInfo.akim.akimImg})`}}/>
                    <div className={'DistrictNav_info_fio'}>
                        {akimInfo?.akim?.name && akimInfo.akim.name[lang].split(' ').map((i, index )=> {return <span key={index + i}>{i}</span>})}
                    </div>
                    <span className={'DistrictNav_info_dolzhost'}>{akimInfo.response[lang]}</span>
                </div>}


            </div>}
            {isBlock && <span className={'DistrictNav_info_title'}>{lanGs['Выбор района'][lang]}</span>}
            <div className={`DistrictNav_item_wrap ${!isBlock}`}>
                {distData?.map((i, index) => {
                    return <div className={`DistrictNav_item ${i.name[lang].includes(distChoice?.name[lang].slice(0, 5)) ? 'active' : ''}`}
                                onClick={() => {

                                    setDistChoice(i)
                                    setAkimInfo(i)
                                    setToMark(i)
                                    setData(null)
                                    setCardSelect(null)
                                }}
                                key={index + i.id}
                    >

                        <span className={'gerb_bg'}
                              style={{backgroundImage: `url(${i.gerbImg})`}}/>
                        {isBlock && <span>{i.id === 0 ? i.name[lang]: i.name[lang].slice(0, -6)}</span>}
                    </div>
                })}
            </div>
        </div>
    );
};

export default DistrictNav;

