export const lanGs ={
    'На нижеуказанный Email высланы инструкции для смены пароля, проверьте Вашу почту.': {
        nameRu: "На нижеуказанный Email высланы инструкции для смены пароля, проверьте Вашу почту.",
        nameKz: "Төменде көрсетілген электрондық пошта парольді өзгерту туралы нұсқаулар жібереді, поштаңызды тексеріңіз.",
    },
    'Пользователь не найден.': {
        nameRu: "Пользователь не найден.",
        nameKz: "Пайдаланушы табылмады.",
    },
    'Проверьте email или пройдите': {
        nameRu: "Проверьте email или пройдите",
        nameKz: "Электрондық поштаны тексеріңіз немесе ",
    },
    'регистрацию': {
        nameRu: "регистрацию",
        nameKz: "тіркеуден өтіңіз",
    },
    'Введите E-mail адрес, который Вы указали при регистрации, мы вышлем Вам ссылку на восстановление пароля.': {
        nameRu: "Введите E-mail адрес, который Вы указали при регистрации, мы вышлем Вам ссылку на восстановление пароля.",
        nameKz: "Тіркелу кезінде көрсеткен e-mail мекенжайын енгізіңіз, біз сізге құпия сөзді қалпына келтіру сілтемесін жібереміз.",
    },
    'Забыли пароль': {
        nameRu: "Забыли пароль",
        nameKz: "Құпия сөзді ұмыттыңыз ба",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Отправить': {
        nameRu: "Отправить",
        nameKz: "Жіберу",
    },
    'Электронная почта': {
        nameRu: "Электронная почта",
        nameKz: "Электрондық пошта",
    },
}


/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
