import React, {useEffect, useState} from "react";
import {Button, Checkbox, Collapse, Form, Radio, Select, Space, Tooltip} from "antd";
import {lanGs} from "../../../../utils/langData";
import './CategoryRadio.css'

import "react-datepicker/dist/react-datepicker.css";
import 'air-datepicker/air-datepicker.css';
import Flatpickr from "react-flatpickr";
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import {Kazakh} from 'flatpickr/dist/l10n/kz.js';
import {CloseCircleOutlined} from "@ant-design/icons";
import 'flatpickr/dist/themes/material_green.css';

const CategoryRadio = ({
                           dataCat,
                           lang,
                           setCardSelect,
                           setToMark,
                           depData,
                           workStatus,
                           setSelectedCheckbox,
                           selectedCheckbox,
                           setIsStatus,
                           setDepartmentId,
                           setIsPeriod,
                           setData
                       }) => {
    const [itemBox, setItemBox] = useState(null);


    const [form] = Form.useForm();

    const [dateRange, setDateRange] = useState(null);
    const [value, setValue] = useState('current');
    const [collapseItems, setCollapseItems] = useState(null);


    const onChange = e => {
        setCardSelect(null);
        // Проверяем, был ли этот чекбокс уже выбран
        if (e.target.value === selectedCheckbox) {
            // Сбросить выбор
            setSelectedCheckbox(null);
        } else {
            // Установить новое значение
            setSelectedCheckbox(e.target.value);
        }
    };
    const onChangeR = (e) => {

        setValue(e.target.value);
    };
    const onReset = () => {
        setItemBox(null)
        setCardSelect(null);
        setToMark(null)
        setSelectedCheckbox(null);/*!!!!!!!!!!!!!!!!!!!!!!*/
        setDateRange(null); // Сброс даты
        setValue(1);
        setIsStatus(null)
        setDepartmentId(null)
        setIsPeriod(null)
    };

    const onFinish = (values) => {
        if (values.statusId || values.depId || dateRange) {


            setSelectedCheckbox(null);/*!!!!!!!!!!!!!!!!!!!!!!*/
            setIsPeriod(values.period === 'period' ? dateRange : null)
            setIsStatus(values?.statusId ? values.statusId : null)
            setCardSelect(null);
            setDepartmentId(values?.depId ? values.depId : null)
        }
    };

    const handleResetDates = () => {
        setDateRange(null); // Сброс даты
    };

    useEffect(() => {
        // let datas2 = !dataCat? dataCat.filter(i => i.value > 0): dataCat
        let datas =   dataCat?.map((category, index) => ({
            label: category.name[lang],
            key: index + 1,
            children: category.subs.map(sub => (

                <Checkbox
                    key={sub.id}
                    value={sub.id}
                    checked={selectedCheckbox === sub.id}
                    onChange={onChange}
                    className={'Checkbox_line'}
                >
                    <div className={`cat_name_wrap ${sub.amount > 0 ? '' : 'disabled'}`}>
                        <span> {sub.name[lang]}</span>
                        <span>{sub.amount}</span>
                    </div>

                </Checkbox>
            ))
        }));

        dataCat && setCollapseItems(datas)
    }, [dataCat]);

    return (
        <div className={'CategoryBlock_wrap'}>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{period: 'current'}}
                layout="vertical"
                className={'RadioCategory_form'}
                name={'RadioBlock_filter'}
                id={'catRadio'}
            >
                <div className={'category_text_header'}>
                    <span className={'category_text_header_name'}>{lanGs['Фильтры'][lang]}</span>

                </div>
                <Form.Item name="period" className={'list_for_category'}>
                    <Radio.Group onChange={onChangeR} value={value} className={'Period_filter_wrapper'}>
                        <Space direction="vertical">
                            <Radio value={'current'}>{lanGs['Текущий год'][lang]}</Radio>
                            <Radio value={'period'} style={{paddingBottom: `${value === 'period' ? '25px' : '0'}`}}>
                                <div className={'Period_filter_wrap'}>
                                    <span>{lanGs['Период проведения работ'][lang]}</span>
                                    <div className={'Flatpickr_wrap'}>
                                        {value === 'period' ? (
                                            <><Flatpickr
                                                className={'Period_filter_calendar'}
                                                value={dateRange}

                                                onChange={([start, end]) => setDateRange([start, end])}
                                                onClose={() => {
                                                    // Опционально: добавьте логику для проверки, была ли выбрана дата
                                                    setDateRange(null); // Сбросить дату при закрытии без выбора

                                                }}
                                                options={{
                                                    mode: "range",
                                                    locale: lang === 'nameRu' ? Russian : Kazakh,
                                                    dateFormat: "d.m.Y",

                                                }}
                                                placeholder="Выберите период"
                                            />
                                                {dateRange?.every(item => item !== null) &&
                                                    <button onClick={handleResetDates}><CloseCircleOutlined/></button>} {/* Кнопка сброса */}
                                            </>
                                        ) : null}

                                    </div>
                                </div>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name={'statusId'} className={'RadioCategory_status'} label={lanGs["Статус"][lang]}>

                    <Select style={{width: '100%',}} popupClassName={'RadioCategory_dep_popup'} options={workStatus?.map(i => {
                        return {value: i.id, label: i.shortName ? i.shortName[lang] : i.name[lang]}
                    })} allowClear size="small" placeholder={lanGs['не выбран'][lang]}/>
                </Form.Item>
                <Form.Item name={'depId'} className={'RadioCategory_dep'} label={lanGs["Ответственный орган"][lang]}>
                    <Select style={{width: '100%',}} popupClassName={'RadioCategory_dep_popup'} options={depData?.map(i => {
                        return {value: i.id, title: i.name[lang], label: i.shortName ? i.shortName[lang] : i.name[lang]}
                    })} allowClear size="small" placeholder={lanGs['не выбран'][lang]}/>

                </Form.Item>
                <Form.Item>
                    <Space className={'form_event_btn'}>

                        <Tooltip placement="topLeft" color={"#146eb0"} title={lanGs[`Применить фильтры`][lang]}>
                            <Button type="primary" htmlType="submit" className={'category_submit_btn'}>{lanGs['Показать'][lang]}</Button>
                        </Tooltip>
                        <Tooltip placement="topLeft" color={"#146eb0"} title={lanGs[`Сбросить фильтры`][lang]}>
                            <Button htmlType="reset" onClick={onReset} className={'category_reset_btn'}>{lanGs['Сбросить'][lang]}</Button>
                        </Tooltip>
                    </Space>
                </Form.Item>
                <div className={'category_text_header'}>
                    <span className={'category_text_header_name padd10'}>{lanGs['Категории'][lang]}</span>

                </div>
                <Form.Item className={'list_for_category'}>
                    {/*<Collapse defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]} items={collapseItems}/>*/}
                    <Collapse defaultActiveKey={["1"]} items={collapseItems}/>
                </Form.Item>
            </Form>
        </div>
    );

};

export default CategoryRadio;
