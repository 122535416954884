export const lanGs = {
    'Ваш голос принят и будет учтен после обновления списка инициатив': {
        nameRu: "Ваш голос принят и будет учтен после обновления списка инициатив",
        nameKz: "Бастамалар тізімі жаңартылғаннан кейін Сіздің дауысыңыз қабылданады және ескеріледі",
    },
    'Проголосовать': {
        nameRu: "Проголосовать",
        nameKz: "Дауыс беру",
    },
    'Пользователь не авторизирован': {
        nameRu: "Пользователь не авторизирован",
        nameKz: "Пайдаланушы рұқсат етілмеген",
    },
    'Для голосования необходимо пройти авторизацию на сайте': {
        nameRu: "Для голосования необходимо пройти авторизацию на сайте",
        nameKz: "Дауыс беру үшін сайтта авторизациядан өту қажет",
    },
    'Авторизоваться': {
        nameRu: "Авторизоваться",
        nameKz: "Кіру",
    },
    'Отменить': {
        nameRu: "Отменить",
        nameKz: "Бас тарту",
    },
    'Инициатива №': {
        nameRu: "Инициатива №",
        nameKz: "№ Бастама",
    },
    'Название инициативы': {
        nameRu: "Название инициативы",
        nameKz: "Бастама атауы",
    },
    'Описание инициативы': {
        nameRu: "Описание инициативы",
        nameKz: "Бастаманың сипаттамасы",
    },
    'Предлагаемые пути решения': {
        nameRu: "Предлагаемые пути решения",
        nameKz: "Ұсынылған шешімдер",
    },
    'Адрес': {
        nameRu: "Адрес",
        nameKz: "Мекен-жайы",
    },
    'Источник финансирования': {
        nameRu: "Источник финансирования",
        nameKz: "Қаржыландыру көзі",
    },
    'Статус': {
        nameRu: "Статус",
        nameKz: "Мәртебесі",
    },
    'Вложения': {
        nameRu: "Вложения",
        nameKz: "Тіркемелер",
    },
    'Ваш голос принят': {
        nameRu: "Ваш голос принят",
        nameKz: "Сіздің дауысыңыз қабылданды",
    },
    'Комментарий модератора': {
    nameRu: "Комментарий модератора",
        nameKz: "Модератордың түсініктемесі",
},
    'Категория инициативы': {
    nameRu: "Категория инициативы",
        nameKz: "Бастама санаты",
},
    'Категория работ': {
        nameRu: "Категория работ",
        nameKz: "Жұмыс санаты",
    },
    'Категория объекта': {
        nameRu: "Категория объекта",
        nameKz: "Нысан санаты",
    },
    'здание ' : {
        nameRu: 'здание ',
        nameKz: "ғимарат ",
    }
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
