import React, {useEffect, useState} from 'react';
import './CardInitiativeBlock.css'
import {Button, message, Modal, Popconfirm} from "antd";
import MapBlock from "../ui/MapBlock";
import ResolutionBlock from "./ResolutionBlock";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {lanGs} from '../../utils/lang/langCardInitiativeBlock'
import {ReactComponent as IMG} from '../../assets/icons/img.svg' ;
import {ReactComponent as DOC} from '../../assets/icons/docs.svg' ;
import DefImg from '../../assets/img.webp'


const CardInitiativeBlock = ({lang, clickInitiative, setClickInitiative, dataCardPas, setDataCardPas, setResetTable, tabsKey, tabBtn}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDesabled, setIsDesabled] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const token = Cookies.get('jwt')
    const roles = Cookies.get('roles');
    const navigate = useNavigate();

    useEffect(() => {
        setIsModalOpen(true)
    }, [clickInitiative, dataCardPas]);

    const handleOk = () => {
        setIsModalOpen(false);
        setClickInitiative(null)
        setDataCardPas(null)
        setIsDesabled(false)

    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setClickInitiative(null)
        setDataCardPas(null)
        setIsDesabled(false)
    };
    const success = () => {
        messageApi.open({
            type: 'success',
            content: `${lanGs['Ваш голос принят'][lang]}`,
            className: 'custom-class',
        });
    };
    let SendVote = (item) => {
        item && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/initiative/vote/${item}`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
               // setClickInitiative(0)
               //  setTimeout(function() {setClickInitiative(item)}, 100)
                setIsDesabled(true)
                setResetTable(true)
                success()
            })
            .catch(function (error) {
                setResetTable(true)
            if(error.request.statusText === 'Unauthorized' ){
                messageApi.open({
                    type: 'error',
                    content: `${lanGs['Unauthorized'][lang]}`,
                    duration: 5,
                }, );
                setTimeout(function() {
                    setResetTable(true)
                    Cookies.remove('jwt')
                    navigate('/login')
                }, 1000)
            }

        });
    }
    let btnEvents = (role, table) => {
        if (token && role.includes('ROLE_MODERATOR') && table !== 0) {
            return <ResolutionBlock
                setIsModalOpen={setIsModalOpen}
                lang={lang}
                dataCardPas={dataCardPas}
                setResetTable={setResetTable}
                tabsKey={tabsKey}
                setClickInitiative={setClickInitiative}
                setDataCardPas={setDataCardPas}/>
        } else if (token && !role.includes('ROLE_MODERATOR')) {
            return <Button size={'large'} className={'CardInitiative_max'} onClick={() => {
                SendVote(dataCardPas?.id)

            }} disabled={!dataCardPas?.voteAllowed || isDesabled}>Проголосовать</Button>
        }

        else {
            return <Popconfirm
                placement="top"
                title={lanGs['Пользователь не авторизирован'][lang]}
                description={lanGs['Для голосования необходимо пройти авторизацию на сайте'][lang]}
                onConfirm={() => {navigate('/login');handleCancel()}}
                okText={lanGs['Авторизоваться'][lang]}
                cancelText={lanGs['Отменить'][lang]}
            >
                <Button size={'large'} className={'CardInitiative_max'}>{lanGs['Проголосовать'][lang]}</Button>
            </Popconfirm>
        }
    }

    return (dataCardPas ?
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered className={'CardInitiative_modal'} footer={false} lang={lang}>
            <div className={'CardInitiative_wrapper'}>
                {contextHolder}
                <div className={'CardInitiative_item'}>
                    <span className={'CardInitiative_title'}>{lanGs['Инициатива №'][lang]}{dataCardPas.id}</span>
                    <span className={'CardInitiative_img' } style={{ backgroundImage:`url(${dataCardPas.categoryWork.image? dataCardPas.categoryWork.image: DefImg})`}}/>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Категория инициативы'][lang]}</span>
                        <span>{dataCardPas?.categoryInitiative?.name[lang]}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Категория работ'][lang]}</span>
                        <span>{dataCardPas?.categoryWork?.name[lang]}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Категория объекта'][lang]}</span>
                        <span>{dataCardPas?.categoryObject?.name[lang]}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Название инициативы'][lang]}</span>
                        <span>{dataCardPas.name}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Описание инициативы'][lang]}</span>
                        <span>{dataCardPas.description}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Предлагаемые пути решения'][lang]}</span>
                        <span>{dataCardPas.suggestion}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Адрес'][lang]}</span>
                        <span>{dataCardPas?.street?.name ? dataCardPas?.street?.name[lang]: ''}  {dataCardPas?.building ? lanGs['здание '][lang] + dataCardPas.building: ''}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Источник финансирования'][lang]}</span>
                        <span>{dataCardPas?.finSource?.name[lang]}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Статус'][lang]}</span>
                        <span>{dataCardPas?.status?.name[lang]}</span>
                    </div>
                    <div className={'CardInitiative_name'}>
                        <span>{lanGs['Вложения'][lang]}</span>
                        <div className={'CardInitiative_docs'}>
                            {dataCardPas?.files?.length > 0 && dataCardPas.files.map(i => {
                                return <a
                                    key={i.id}
                                    href={i.url}
                                    title={i.filename}
                                    download={i.filename}
                                    className={'CardInitiative_docs_item'}
                                >
                                    {filesType(i.type)}

                                </a>
                            })}
                        </div>
                    </div>
                    {dataCardPas.statusComment && <div className={'CardInitiative_name'}>
                        <span>{lanGs['Комментарий модератора'][lang]}</span>
                        <span>{dataCardPas.statusComment}</span>
                    </div>}
                </div>
                <div className={'CardInitiative_map'}>
                    <MapBlock marker={dataCardPas?.geom?.coordinates} isEdit={false}  lang={lang} dataCardPas={dataCardPas}/>
                    {!tabBtn && btnEvents(roles, tabsKey)}
                </div>
            </div>
        </Modal>
            : null
    );
};

export default CardInitiativeBlock;


let filesType =(item) => {
    if(item === 'jpg' || item === 'jpeg' || item === 'png'){
        return <IMG/>
    }
    else{
        return <DOC/>
    }
}
