import React, {useEffect, useState} from 'react';
import './IniMapPage.css'
import BackBlock from "../element/BackBlock";

import DistrictNav from "../element/iniMap/DistrictNav";
import Main from "../element/iniMap/Main";

const IniMapPage = ({lang, setCatCardClick, dataCat, setDataCat, data, setClickInitiative, setData, distChoice, setDistChoice, setMapCatClick, tabBtn, dataCardPas}) => {

    const [toMark, setToMark] = useState(null);
    const [cardSelect, setCardSelect] = useState(null);
    useEffect(() => {
        dataCardPas?.geom?.coordinates && setToMark(dataCardPas.geom.coordinates)

        dataCardPas && setCardSelect(dataCardPas)
    }, [dataCardPas]);
    return (

            <div className={`IniMapPage_wrap`}>
                <BackBlock lang={lang} link={'/'} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>
                <div className={'DistrictNav_block'}>
                    <DistrictNav
                        lang={lang}
                        distChoice={distChoice}
                        setDistChoice={setDistChoice}
                        setToMark={setToMark}
                    />
                    <Main lang={lang}
                          setCatCardClick={setCatCardClick}
                          toMark={toMark}
                          setToMark={setToMark}
                          distChoice={distChoice}
                          setDistChoice={setDistChoice}
                          dataCat={dataCat}
                          setDataCat={setDataCat}
                          setClickInitiative={setClickInitiative}
                          setData={setData}
                          setMapCatClick={setMapCatClick}
                          tabBtn={tabBtn}
                          cardSelect={cardSelect}
                          setCardSelect={setCardSelect}

                    data={data}/>
                </div>
            </div>

    );
};

export default IniMapPage;
