export const lanGs = {
    'Твоя инициатива важна для города!': {
        nameRu: "Твоя инициатива важна для города!",
        nameKz: "Сіздің бастамаңыз Қала үшін маңызды!",
    },
    'Здесь вы можете': {
        nameRu: "Здесь вы можете посмотреть имеющиеся предложения жителей города Алматы и проголосовать:",
        nameKz: "Мұнда Сіз Алматы қаласы тұрғындарының қолда бар ұсыныстарын көре аласыз және дауыс бере аласыз:",
    },
    'Инициативы на голосовании': {
        nameRu: "Инициативы на голосовании",
        nameKz: "Дауыс беру бастамалары",
    },
    'Архив инициатив': {
        nameRu: "Архив инициатив",
        nameKz: "Бастамалар мұрағаты",
    },
    'Все инициативы': {
        nameRu: "Все инициативы",
        nameKz: "Барлық бастамалар",
    },
    'Всего поданных инициатив': {
        nameRu: "Всего поданных инициатив",
        nameKz: "Барлық берілген бастамалар",
    },
    'Модерация': {
        nameRu: "Модерация",
        nameKz: "Модерация",
    },
    'Опубликовать инициативу': {
        nameRu: "Опубликовать инициативу",
        nameKz: "Бастаманы жариялау",
    },
    'Правила': {
        nameRu: "Правила",
        nameKz: "Ережелер",
    },
    'Новости': {
        nameRu: "Новости",
        nameKz: "Жаңалықтар",
    },
    'Аналитика': {
        nameRu: "Аналитика",
        nameKz: "Аналитика",
    },
    'Бюджет народного участия': {
        nameRu: "Бюджет народного участия",
        nameKz: "Халық қатысатын Бюджет",
    },
    'Подать предложение': {
        nameRu: "Подать предложение",
        nameKz: "Ұсыныс беру",
    },
    'Мониторинг работ': {
        nameRu: "Мониторинг работ",
        nameKz: "Жұмыс мониторингі",
    },
    'Посмотреть на карте': {
        nameRu: "Посмотреть на карте",
        nameKz: "Жұмыс мониторингі",
    },
    'noSocial': {
        nameRu: "Для подачи инициативы пройдите полную регистрацию",
        nameKz: "Бастаманы беру үшін толық тіркеуден өтіңіз",
    }, 'Ремонтные работы по г.Алматы': {
        nameRu: "Ремонтные работы по г.Алматы",
        nameKz: "Алматы қаласы бойынша жөндеу жұмыстары",
    },
    'О проекте': {
        nameRu: "О проекте",
        nameKz: "Жоба туралы",
    },
}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
