import React from 'react';
import '../Style/PopupItem.css'
import {ReactComponent as Mark} from '../Image/mark.svg'
import {ReactComponent as Like} from '../Image/like.svg'

import {ReactComponent as Cat} from "../Image/cat.svg";
import {Tooltip} from "antd";
import {lanGs} from "../langData";
const PopupItem = ({data, lang}) => {

    return (
        <div className={'PopupItem_wrap'}>
            {/*название*/}
            {data.name && <div className={`Popup_items name ${data.name?.length > 30 ? 'transfer_text ' : ''}`} key={data.name + 'id' + Math.random(500)} title={'Название'}>
                <span>{data.name}</span>
            </div>}


            {(data?.categoryInitiative || data?.categoryObject || data?.categoryWork) && <div className={'Popup_items_cat'}>

                {data?.categoryInitiative && <div className={'Popup_item'}>
                    <span className={''}> {lanGs['Категория:'][lang]} </span>
                    <span> {data?.categoryInitiative && data.categoryInitiative?.name[lang]}</span>
                </div>}

                {data?.categoryObject && <div className={'Popup_item'}>
                    <span className={'Tooltip_wrap_text_block_text'}>{lanGs['Объект:'][lang]} </span>
                    <span>{data?.categoryObject && data.categoryObject?.name[lang]}</span>
                </div>}

                {data?.categoryWork && <div className={'Popup_item'}>
                    <span className={'Tooltip_wrap_text_block_text'}>{lanGs['Работа:'][lang]} </span>
                    <span>{data?.categoryWork && data.categoryWork?.name[lang]}</span>
                </div>}


            </div>}


            {/*район*/}
            {data.district  && <div className={`Popup_items`} key={'district ' + Math.random(300)} title={'Район'}>
                <div className={'popup_line_style'}>
                    <span><Mark/></span>  <span>{data?.district[lang] ? data.district[lang] + '': '' }</span>
                </div>
            </div>}



            {/*Адрес*/}
            {(data?.street || data?.building ) && <div className={`Popup_items`} key={'address ' + Math.random(100)} title={'Адрес'}>
                    <span>
                        Адрес: {data?.street ? ', ' +lanGs['улица'][lang] + ': ' +  data?.street?.name[lang]+', ' : ''}
                        {data?.building?.length ? lanGs['дом'][lang] + ': ' +   data?.building : ''}
                    </span>
                </div>}

            <div className={'Popup_items_double'}>
               {/* статус*/}
                {data.status && <div className={`Popup_items`} key={'status ' + Math.random(10)} title={'Статус'}>
                    <span style={{color: `${data.status?.color ? data.status.color: '#000000'}`}}>⬤</span>
                    <span>{data.status.name[lang]}</span>
                </div>}

               {/* лайки*/}
                {/*{data.neg?.toString() && data.pos?.toString() && <div title={'Лайки'} className={'Popup_items_likes'}>
                    <div className={`Popup_items like_pos`} key={'like ' + 'pos'} >
                       <Like/>
                        <span>{data.pos}</span>
                    </div>
                    <div className={`Popup_items likes like_neg`} key={data.neg + id + 'neg'} >
                        <Like />
                        <span>{data.neg}</span>
                    </div>

                </div>}*/}
            </div>

        </div>
    );
};

export default PopupItem;


