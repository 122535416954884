import React, {useEffect, useState} from 'react';
import './MapBlock.css'
import { YMaps, Map, Placemark, GeolocationControl, ZoomControl, SearchControl } from 'react-yandex-maps';
import {lanGs} from '../../utils/lang/langMapBlock'

const MapBlock = ({lang, placemarkGeometry, setPlacemarkGeometry, marker, isEdit=true, dataCardPas}) => {
const [center, setCenter] = useState([43.244349, 76.921753])
    const handleMapClick = (event) => {
        const coordinates = event.get('coords');
        setPlacemarkGeometry(coordinates);
    };
    useEffect(() => {
        marker && setCenter(marker.reverse())
    }, [marker]);
    return (
        <div className={'MapBlock_wrap'}>
            <YMaps>
                <Map
                    onClick={!marker && isEdit && handleMapClick}
                    defaultState={{center: center, zoom: marker ? 14: 12}}
                    width="100%"
                    height="100%"
                >
                    <GeolocationControl options={{float: 'left'}}/>
                    <ZoomControl options={{float: 'right'}}/>
                    {/*<SearchControl options={{ float: 'right' }} />*/}
                    {!marker && placemarkGeometry && isEdit && <Placemark geometry={placemarkGeometry} />}
                    {marker && <Placemark geometry={center}/>}
                    {/* Добавьте другие метки Placemark по необходимости */}
                </Map>
            </YMaps>
            {!marker && isEdit && <div className={'map_coords'}>
                <span><b>{lanGs['широта:'][lang]}</b> {placemarkGeometry && placemarkGeometry[0]?.toString()?.slice(0, 10)}</span>
                <span><b>{lanGs['долгота:'][lang]}</b> {placemarkGeometry && placemarkGeometry[1]?.toString()?.slice(0, 10)}</span>
            </div>}
        </div>
    );
};

export default MapBlock;
