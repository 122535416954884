export const lanGs = {
    'Правила при подаче инициативы': {
        nameRu: "Правила при подаче инициативы",
        nameKz: "Бастама беру кезіндегі ережелер",
    },
    'Для того, чтобы Пользователь мог подавать собственные инициативы, он должен:': {
        nameRu: "Для того, чтобы Пользователь мог подавать собственные инициативы, он должен:",
        nameKz: "Пайдаланушы өз бастамаларын ұсына алуы үшін ол:",
    },
    '1n1': {
        nameRu: "1. быть не моложе 18 лет.",
        nameKz: "1. кем дегенде 18 жаста болыңыз.",
    },
    '1n2':{
        nameRu: "2. Быть зарегистрированным Пользователем Сервиса предложения инициатив (далее - Сервис).",
        nameKz: "2. Бастамаларды ұсыну сервисінің (бұдан әрі - Сервис) тіркелген пайдаланушысы болу.",
    },
    '1n3': {
        nameRu: "3. Осуществить стандартный вход на сайт Сервиса. В случае входа через социальные сети возможно только голосование.",
        nameKz: "3. Сервис сайтына стандартты кіруді жүзеге асыру. Әлеуметтік желілер арқылы кірген жағдайда ғана дауыс беруге болады.",
    },
    '1n4': {
        nameRu: "4. Заполнить все поля формы ввода. В тексте должно быть точно сформулированы описание инициативы и пути ее решения.",
        nameKz: "4. Енгізу формасының барлық өрістерін толтырыңыз. Мәтінде бастаманың сипаттамасы және оны шешу жолдары дәл тұжырымдалуы керек.",
    },
    'Инициатива не будет допущена к голосованию, если:': {
        nameRu: "Инициатива не будет допущена к голосованию, если:",
        nameKz: "Бастама дауыс беруге жіберілмейді, егер:",
    },
    '2n1': {
        nameRu: "1. Пропагандирует ненависть, дискриминацию по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления, угрозы в адрес конкретных лиц или организаций, ущемляет права меньшинств, нарушает права несовершеннолетних, причиняет им вред в любой форме.",
        nameKz: "1. Нәсілдік, этникалық, жыныстық, діни, әлеуметтік белгілері бойынша жеккөрушілікті, кемсітушілікті насихаттайды, белгілі бір адамдарға немесе ұйымдарға қатысты қорлауды, қорқытуды қамтиды, азшылықтардың құқықтарына нұқсан келтіреді, кәмелетке толмағандардың құқықтарын бұзады, оларға кез келген нысанда зиян келтіреді.",
    },
    '2n2': {
        nameRu: "2. Распространяет персональные данные третьих лиц без их согласия.",
        nameKz: "2. Үшінші тұлғалардың дербес деректерін олардың келісімінсіз таратады.",
    },
    '2n3': {
        nameRu: "3. Преследует коммерческие цели, содержит спам, рекламную информацию.",
        nameKz: "3. Коммерциялық мақсаттарды көздейді, спам, жарнамалық ақпаратты қамтиды.",
    },
    '2n4': {
        nameRu: "4. Нарушает любые применимые нормы права.",
        nameKz: "4. Кез келген қолданыстағы құқық нормаларын бұзады.",
    },
    '2n5': {
        nameRu: "5. Содержит нецензурную лексику и ее производные.",
        nameKz: "5. Содержит нецензурную лексику и ее производные.",
    },
    '2n6': {
        nameRu: "6. Содержит жалобы, просьбы личного характера, сообщения и прошения, связанные с персональными жизненными ситуациями и требующие ответа.",
        nameKz: "6. Жеке өмірлік жағдайларға байланысты және талап ететін шағымдарды, жеке сипаттағы өтініштерді, хабарламалар мен өтініштерді қамтиды жауап.",
    },
    '2n7': {
        nameRu: "7. Содержит призывы к экстремистской деятельности.",
        nameKz: "7. Онда экстремистік әрекетке шақырулар бар.",
    },
    '2n8': {
        nameRu: "8. Носит отвлеченный характер.",
        nameKz: "8. Бұл алаңдаушылық тудырады.",
    },
    '2n9': {
        nameRu: "9. Не содержит законченного высказывания.",
        nameKz: "9. Толық мәлімдеме жоқ.",
    },
    '2n10': {
        nameRu: "10. Смысл текста трудно или невозможно уловить.",
        nameKz: "10. Мәтіннің мағынасын түсіну қиын немесе мүмкін емес.",
    },
    '2n11': {
        nameRu: "11. Текст целиком или преимущественно набран заглавными буквами.",
        nameKz: "11. Мәтін толығымен немесе негізінен бас әріптермен жазылады.",
    },
    '2n12': {
        nameRu: "12. Текст не разбит на предложения.",
        nameKz: "12. Мәтін сөйлемдерге бөлінбейді.",
    },
    '2n13': {
        nameRu: "13. Возраст лица, подающего инициативу, младше 18 лет.",
        nameKz: "13. Бастамашы адамның жасы 18 жасқа толмаған.",
    },
    'Правила проведения голосования:': {
        nameRu: "Правила проведения голосования:",
        nameKz: "Дауыс беруді өткізу қағидалары:",
    },
    '3n1': {
        nameRu: "1. Срок проведения голосования по инициативе – 6 месяцев с момента допуска инициативы на голосование модератором, минимальное количество голосов,  необходимое для передачи в акимат г. Алматы - 500.  По истечении указанного срока инициатива будет снята с голосования независимо от итогов и перемещена в архив.",
        nameKz: "1. Бастама бойынша дауыс беруді өткізу мерзімі - бастаманы модератор дауыс беруге жіберген сәттен бастап 6 ай, Алматы қаласының әкімдігіне беру үшін қажетті дауыстардың ең аз саны-500.  Көрсетілген мерзім өткеннен кейін бастама қорытындыларына қарамастан дауыс беруден алынып, мұрағатқа көшіріледі.",
    },

}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
