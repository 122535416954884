export const lanGs = {
    'Не указан': {
        nameRu: "Не указан",
        nameKz: "Көрсетілмеген",
    },
    'Поиск по наименованию': {
        nameRu: "Поиск по наименованию",
        nameKz: "Атауы бойынша іздеу",
    },
    'Наименование': {
        nameRu: "Наименование",
        nameKz: "Атауы",
    },
    'Категория': {
        nameRu: "Категория",
        nameKz: "Санат",
    },
    'Категория работ': {
        nameRu: "Категория работ",
        nameKz: "Жұмыс санаты",
    },
    'Объект': {
        nameRu: "Объект",
        nameKz: "Нысан",
    },
    'Район': {
        nameRu: "Район",
        nameKz: "Аудан",
    },
    'Дата регистрации': {
        nameRu: "Дата регистрации",
        nameKz: "Тіркелген күні",
    },
    'Голосов': {
        nameRu: "Голосов",
        nameKz: "Дауыстар",
    },
    'Статус': {
        nameRu: "Статус",
        nameKz: "Мәртебесі",
    },


}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */


