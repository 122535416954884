export const lanGs = {
    'Инициатива успешно направлена на голосование': {
        nameRu: "Инициатива успешно направлена на голосование",
        nameKz: "Бастама дауыс беруге сәтті бағытталған",
    },
    'Ваш комментарий направлен на почту автора инициативы': {
        nameRu: "Ваш комментарий направлен на почту автора инициативы",
        nameKz: "Сіздің пікіріңіз бастама авторының поштасына бағытталған",
    },
    'Отказ направлен на почту автора инициативы': {
        nameRu: "Отказ направлен на почту автора инициативы",
        nameKz: "Бас тарту бастама авторының поштасына жіберілді",
    },
    'Подтвердите отправку инициативы на согласование': {
        nameRu: "Подтвердите отправку инициативы на согласование",
        nameKz: "",
    },
    'Отправить': {
        nameRu: "Отправить",
        nameKz: "Жіберу",
    },
    'На доработку': {
        nameRu: "На доработку",
        nameKz: "Пысықтау үшін",
    },
    'Комментарий (причина возврата на доработку)': {
        nameRu: "Комментарий (причина возврата на доработку)",
        nameKz: "Түсініктеме (пысықтауға оралу себебі)",
    },
    'Обязательное поле': {
        nameRu: "Обязательное поле",
        nameKz: "Міндетті өріс",
    },

    'Отказ': {
        nameRu: "Отказ",
        nameKz: "Бас тарту",
    },
    'Комментарий (причина отмены)': {
        nameRu: "Комментарий (причина отмены)",
        nameKz: "Түсініктеме (бас тарту себебі)",
    },

    'На голосование': {
        nameRu: "На голосование",
        nameKz: "Дауыс беруге",
    },

    'Unauthorized': {
    nameRu: "Требуется повторная авторизация. Вас перенаправит на страницу авторизации",
        nameKz: "Қайта авторизациялау қажет. Сізді авторизация бетіне бағыттайды",
},
    'token': {
        nameRu: "Срок действия ключа авторизации истек, пройдите повторную авторизацию",
        nameKz: "Авторизация кілтінің мерзімі аяқталды, қайта авторизациядан өтіңіз",
    },'Одобрен': {
        nameRu: "Одобрен",
        nameKz: "Мақұлданды",
    },'Подтвердите отправку инициативы в акимат': {
        nameRu: "Подтвердите отправку инициативы в акимат",
        nameKz: "Бастаманың әкімдікке жіберілгенін растаңыз",
    },
    'Инициатива успешно перенесена в список для акимата': {
        nameRu: "Инициатива успешно перенесена в список для акимата",
        nameKz: "Бастама әкімдік үшін тізімге сәтті көшірілді"
    },


}

/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
