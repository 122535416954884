export const lanGs ={
    'Пользователь с таким Email не найден': {
        nameRu: "Пользователь с таким Email не найден",
        nameKz: "Мұндай Email бар пайдаланушы табылмады",
    },
    'Пользователь не подтвердил Email': {
        nameRu: "Пользователь не подтвердил Email",
        nameKz: "Пайдаланушы Email-ді растаған жоқ",
    },
    'Неверный пароль': {
        nameRu: "Неверный пароль",
        nameKz: "Дұрыс құпия сөз емес",
    },
    'Войдите в систему': {
        nameRu: "Войдите в систему",
        nameKz: "Жүйеге кіріңіз",
    },
    'Зарегистрироваться': {
        nameRu: "Зарегистрироваться",
        nameKz: "Тіркелу",
    },
    'Не заполнено': {
        nameRu: "Не заполнено",
        nameKz: "Толық емес",
    },
    'Пароль': {
        nameRu: "Пароль",
        nameKz: "Құпия сөз",
    },
    'Требование к паролю: латиница, цифры, спецсимволы, не менее 6 знаков': {
        nameRu: "Требование к паролю: латиница, цифры, спецсимволы, не менее 6 знаков",
        nameKz: "Парольге қойылатын талаптар: латын, сандар, арнайы таңбалар, кемінде 6 таңба",
    },
    'Вход': {
        nameRu: "Вход",
        nameKz: "Кіру",
    },
    'Забыли пароль?': {
        nameRu: "Забыли пароль?",
        nameKz: "Құпия сөзді ұмыттыңыз ба?",
    },
    'Войти через Gmail': {
        nameRu: "Войти через Gmail",
        nameKz: "Gmail арқылы кіру",
    },
    'Войти через Facebook': {
        nameRu: "Войти через Facebook",
        nameKz: "Facebook арқылы кіру",
    },
    'Войти через Instagram': {
        nameRu: "Войти через Instagram",
        nameKz: "Instagram арқылы кіру",
    },
    'При входе через социальные сети доступно только голосование': {
        nameRu: "При входе через социальные сети доступно только голосование",
        nameKz: "Әлеуметтік желілер арқылы кірген кезде тек дауыс беруге болады",
    },
}


/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
