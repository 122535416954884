export const lanGs = {
  "Поиск по названию": {
    nameRu: "Поиск по названию",
    nameKz: "Аты бойынша іздеу",
  },
  "Без категории": {
    nameRu: "Без категории",
    nameKz: "Санат жоқ",
  },
  Расположение: {
    nameRu: "Расположение",
    nameKz: "Орналасқан жері",
  },
  Пропустить: {
    nameRu: "Пропустить",
    nameKz: "Өткізіп жіберу",
  },
  Перейти: {
    nameRu: "Перейти",
    nameKz: "Өту",
  },
  "Вы остались": {
    nameRu: "Вы остались",
    nameKz: "Сіз қалдыңыз",
  },
  Район: {
    nameRu: "Район",
    nameKz: "Аудан",
  },
  Адрес: {
    nameRu: "Адрес",
    nameKz: "Мекен-жайы",
  },
  "Введите адрес": {
    nameRu: "Введите адрес",
    nameKz: "Мекенжайды енгізіңіз",
  },
  Статус: {
    nameRu: "Статус",
    nameKz: "Мәртебесі",
  },
  Описание: {
    nameRu: "Описание",
    nameKz: "Сипаттама",
  },
  Документы: {
    nameRu: "Документы",
    nameKz: "Құжаттар",
  },
  "Скачать документ": {
    nameRu: "Скачать документ",
    nameKz: "Құжатты жүктеу",
  },
  Категории: {
    nameRu: "Категории",
    nameKz: "Санаттар",
  },
  "Показать все": {
    nameRu: "Показать все",
    nameKz: "Барлығын көрсету",
  },
  Приблизить: {
    nameRu: "Приблизить",
    nameKz: "Жуықтау",
  },
  "Нет метки": {
    nameRu: "Нет метки",
    nameKz: "Белгі жоқ",
  },
  Ответственные: {
    nameRu: "Ответственные",
    nameKz: "Жауапты",
  },
  Куратор: {
    nameRu: "Куратор",
    nameKz: "Куратор",
  },
  "Оставить отзыв": {
    nameRu: "Оставить отзыв",
    nameKz: "Пікір қалдыру",
  },
  Подрядчик: {
    nameRu: "Подрядчик",
    nameKz: "Мердігер",
  },
  "Информация о подрядчике": {
    nameRu: "Информация о подрядчике",
    nameKz: "Мердігер туралы ақпарат",
  },
  ФИО: {
    nameRu: "ФИО",
    nameKz: "ТАӘ",
  },
  Телефон: {
    nameRu: "Телефон",
    nameKz: "Телефон",
  },
  Сроки: {
    nameRu: "Сроки",
    nameKz: "Мерзімдері",
  },
  "Сроки и статус": {
    nameRu: "Сроки и статус",
    nameKz: "Мерзімдері мен мәртебесі",
  },
  Недовольны: {
    nameRu: "Недовольны",
    nameKz: "Бақытсыз",
  },
  Довольны: {
    nameRu: "Довольны",
    nameKz: "Бақытты",
  },
  "Вы можете выбрать несколько": {
    nameRu: "Вы можете выбрать несколько",
    nameKz: "Сіз бірнеше таңдай аласыз",
  },
  "Не выбран отзыв": {
    nameRu: "Не выбран отзыв",
    nameKz: "Шолу таңдалмаған",
  },
  "Отзыв отправлен": {
    nameRu: "Отзыв отправлен",
    nameKz: "Кері байланыс жіберілді",
  },
  Что: {
    nameRu: "Что",
    nameKz: "Не",
  },
  понравилось: {
    nameRu: "понравилось",
    nameKz: "ұнады",
  },
  "не понравилось": {
    nameRu: "не понравилось",
    nameKz: "ұнамады",
  },
  "Вы хотите оставить обращение?": {
    nameRu: "Хотите оставить обращение?",
    nameKz: "Өтінішті қалдырғыңыз келе ме?",
  },
  "Вы будете перенаправлены на сайт": {
    nameRu: "Вы будете перенаправлены на сайт OpenAlmaty.",
    nameKz: "Сіз Open Almaty сайтына қайта бағытталасыз.",
  },
  "Не указан": {
    nameRu: "Не указан",
    nameKz: "Көрсетілмеген",
  },
  "Не указана": {
    nameRu: "Не указана",
    nameKz: "Көрсетілмеген",
  },
  "Адрес объекта": {
    nameRu: "Адрес объекта",
    nameKz: "Объектінің мекенжайы",
  },
  "Отправить вашу оценку?": {
    nameRu: "Отправить вашу оценку?",
    nameKz: "Сіздің ұпайыңызды жібересіз бе?",
  },
  Отправить: {
    nameRu: "Отправить",
    nameKz: "Жіберу",
  },
  Отменить: {
    nameRu: "Отменить",
    nameKz: "Бас тарту",
  },
  "Оценка отправлена": {
    nameRu: "Оценка отправлена",
    nameKz: "Баға жіберілді",
  },
  "Панель руководителя": {
    nameRu: "Панель руководителя",
    nameKz: "Басқару тақтасы",
  },
  найдено: {
    nameRu: "найдено",
    nameKz: "табылды",
  },
  "Выбор района": {
    nameRu: "Выбор района",
    nameKz: "Аудан таңдау",
  },
  "Мониторинг районов": {
    nameRu: "Мониторинг районов",
    nameKz: "Аудандар мониторингі",
  },
  "домашняя страница": {
    nameRu: "домашняя страница",
    nameKz: "Басты бет",
  },
  "Центр развития города Алматы": {
    nameRu: "Центр развития города Алматы",
    nameKz: "Алматы қаласын дамыту орталығы",
  },
  "Акимат города Алматы": {
    nameRu: "Акимат  города Алматы",
    nameKz: "Алматы қаласының әкімдігі",
  },
  "Управление Цифровизации города Алматы": {
    nameRu: "Управление   Цифровизации города Алматы",
    nameKz: "Алматы қаласы Цифрландыру басқармасы",
  },
  Вход: {
    nameRu: "Вход",
    nameKz: "Кіру",
  },
  "Не указанно": {
    nameRu: "Не указанно",
    nameKz: "Көрсетілмеген",
  },
  "Главная страница": {
    nameRu: "Главная страница",
    nameKz: "Басты бет",
  },
  "Ситуационный центр": {
    nameRu: "Ситуационный центр",
    nameKz: "Ахуалдық орталық",
  },
    "Загрузка": {
  nameRu: "Загрузка...",
      nameKz: "Жүктеу...",
},
  "Выбрано ": {
    nameRu: "Выбрано ",
    nameKz: "Таңдалған ",
  },
  "Сбросить фильтры": {
    nameRu: "Сбросить фильтры",
    nameKz: "Сүзгілерді қалпына келтіріңіз",
  },
  "Применить фильтры": {
    nameRu: "Применить фильтры",
    nameKz: "Сүзгілерді қолдану",
  },
  "Применить": {
    nameRu: "Применить",
    nameKz: "Қолдану",
  },
  "Очистить": {
    nameRu: "Очистить",
    nameKz: "Тазалау",
  },
  "Показать": {
    nameRu: "Показать",
    nameKz: "Көрсету",
  },
  "Категория объекта инициативы:": {
    nameRu: "Категория объекта инициативы:",
    nameKz: "Бастама объектісінің санаты:",
  },
  "Категория инициативы:": {
    nameRu: "Категория инициативы:",
    nameKz: "Бастама санаты:",
  },
  "Категории инициатив": {
    nameRu: "Категории инициатив",
    nameKz: "Бастамалар санаттары",
  },
  "Категория работы:": {
    nameRu: "Категория работы:",
    nameKz: "Жұмыс санаты:",
  },
  "Категория:": {
    nameRu: "Категория:",
    nameKz: "Санат:",
  },
  "Объект:": {
    nameRu: "Объект:",
    nameKz: "Нысан:",
  },
  "Работа:": {
    nameRu: "Работа:",
    nameKz: "Жұмыс:",
  },  "Фильтр": {
    nameRu: "Фильтр",
    nameKz: "Сүзгі",
  }, "Категория работ инициативы:": {
    nameRu: "Категория работ инициативы:",
    nameKz: "Жұмыс санаты бастамалар:",
  },
  "дом": {
    nameRu: "дом",
    nameKz: "үй",
  },
  "улица": {
    nameRu: "улица",
    nameKz: "көше",
  },
  "район": {
    nameRu: "район",
    nameKz: "аудан",
  }, "Выбрать категорию": {
    nameRu: "Выбрать категорию",
    nameKz: "Санатты таңдаңыз",
  },
};
