import React, {useEffect, useState} from 'react';
import './Initiatives.css'
import axios from "axios";
import HeaderBlock from "./HeaderBlock";
import FooterBlock from "./FooterBlock";
import Cookies from 'js-cookie';

import {Route, Routes, useNavigate} from "react-router-dom";
import LoginBlock from "../common/auth/LoginBlock";
import RegBlock from "../common/auth/RegBlock";
import Dashboard from '../common/pages/Dashboard'
import ForgotBlock from "../common/auth/ForgotBlock";
import ChangePasBlock from "../common/auth/ChangePasBlock";
import ConfirmEmail from "../common/auth/ConfirmEmail";
import AddInitiatives from "../common/pages/AddInitiatives";
import RulesBlock from "../common/pages/RulesBlock";
import AboutProject from "../common/pages/AboutProject";
import TableBlock from "../common/pages/TableBlock";
import NotPage from "../common/pages/NotPage";
import {ConfigProvider, message} from "antd";
import 'dayjs/locale/zh-cn';
import rRu from 'antd/locale/ru_RU';
import rKz from 'antd/locale/kk_KZ';
import CardInitiativeBlock from "../common/element/CardInitiativeBlock";
import WorkMapPage from "../common/pages/WorkMapPage";
import IniMapPage from "../common/pages/IniMapPage";
import AuthSocial from "../common/auth/AuthSocial";
import TableBlockModer from "../common/pages/TableBlockModer";
import {lanGs} from "../utils/lang/langResolutionBlock";
import EditInitiatives from "../common/pages/EditInitiatives";

import jwtDecode from "jwt-decode";


const Initiatives = () => {
    const [lang, setLang] = useState(Cookies.get('lang')? Cookies.get('lang'): "nameRu");
    const [catCardClick, setCatCardClick] = useState(null)
    const [mapCatClick, setMapCatClick] = useState(null)
    const [clickInitiative, setClickInitiative] = useState(null)
    const [to10Data, setTop10Data] = useState(null)
    const [dataCat, setDataCat] = useState(null)
    const [dataCardPas, setDataCardPas] = useState(null)
    const [tabBtn, setTabBtn] = useState(false)
    const [totalInfo, setTotalInfo] = useState(false)
    const [resetTable, setResetTable] = useState(false)
    const [resetCardMain, setResetCardMain] = useState(false)
    const [data, setData] = useState(null)
    const [tabsKey, setTabsKey] = useState(0)
    const [messageApi, contextHolder] = message.useMessage();
    const [distChoice, setDistChoice] = useState({ dist: "г. Алматы", id: 0 });
    const [archiveYears, setArchiveYears] = useState(null)
    const [isYear, setIsYear] = useState(null)
    const navigate = useNavigate();
    const token = Cookies.get('jwt')
    let CatC = Cookies.get('catC')




    useEffect(() => {
        if(token && Date.now() >= ((jwtDecode(token)?.exp*1000) - 60000))
        {message.error(`${lanGs['token'][lang]}`, [5]).then( Cookies.remove('jwt'), Cookies.remove('roles'), navigate('/'))}

    }, [token])
    useEffect(() => {
        axios.get(`/sc-initiatives/api/v1/ref/available-years`).then((res) => {
            setArchiveYears(res.data);
            setIsYear(res.data[0])
        })

    }, [])
    /*запрос данных для скрол панели и данных по общему ко-ву заявок*/
    useEffect(() => {
      /*  Cookies.remove('catC');*/
        axios.get(`/sc-initiatives/api/v1/dashboard/top10`).then((res) => {
            setTop10Data(res.data);
        })
        axios.get(`/sc-initiatives/api/v1/dashboard/total-info`).then((res) => {
            setTotalInfo(res.data);
            setResetCardMain(false)
        })

    }, [resetCardMain])

    /* запрос данных для отрисовки карточек основного окна*/
    useEffect(() => {
        // setData(null)
        axios.get(`/sc-initiatives/api/v1/dashboard/category?archived=${tabBtn}&distId=${distChoice.id !== 0? distChoice.id: ''}`).then((res) => {
            setDataCat(res.data);
            setResetCardMain(false)
        })

    }, [tabBtn, resetCardMain, distChoice])

    /* запрос данных для отрисовки паспорта инициативы*/
    useEffect(() => {
        clickInitiative &&   axios({
            method: "get",
            url: `/sc-initiatives/api/v1/initiative/detail/${clickInitiative}`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {

                setDataCardPas(res.data);

            })
            .catch(function (error) {
                if(error.request.statusText === 'Unauthorized' ){
                    messageApi.open({
                        type: 'error',
                        content: `${lanGs['Unauthorized'][lang]}`,
                        duration: 5,
                    }, );
                    setTimeout(function() {

                        Cookies.remove('jwt')
                        navigate('/login')
                    }, 1000)
                }

            });
    }, [clickInitiative])
    useEffect(() => {
        setData(null)
        axios({
            method: "post",
            url: `/sc-initiatives/api/v1/initiative/all`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
            data: {
                "archived": tabBtn,
                "categoryInitiativeId": catCardClick?.categoryIds  ? catCardClick?.categoryIds:CatC ,
                "distId": distChoice?.id && distChoice?.id !== 0? distChoice.id: null,
                "categoryObjectId": mapCatClick?.objectId ? mapCatClick?.objectId[0]: null,
                "categoryWorkId": mapCatClick?.workId ? mapCatClick.workId: null,
             /*   "statusId": 1,*/
                "year":tabBtn ? isYear: null

            },
        })
            .then((res) => {

                setData(res.data)
                setResetTable(false)

            })
            .catch(function (error) {
                if(error.request.statusText === 'Unauthorized' ){
                    messageApi.open({
                        type: 'error',
                        content: `${lanGs['Unauthorized'][lang]}`,
                        duration: 5,
                    }, );
                    setTimeout(function() {
                        Cookies.remove('jwt')
                        navigate('/login')
                    }, 1000)
                }

            });
    }, [CatC, resetTable, token, tabBtn, distChoice, catCardClick, mapCatClick, isYear]);

    return (<ConfigProvider locale={lang !== 'nameRu' ? rKz : rRu}>
            <div className={'Initiatives_wrapper'} >
                <HeaderBlock lang={lang} setLang={setLang}/>
                <Routes>
                    <Route exact path="/"
                           element={<Dashboard
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setClickInitiative={setClickInitiative}
                               clickInitiative={clickInitiative}
                               to10Data={to10Data}
                               dataCat={dataCat}
                               tabBtn={tabBtn}
                               setTabBtn={setTabBtn}
                               totalInfo={totalInfo}
                               setResetTable={setResetTable}

                           />}/>
                    <Route exact path="/add-initiatives" element={<AddInitiatives lang={lang} setCatCardClick={setCatCardClick} setResetCardMain={setResetCardMain} setData={setData} setDistChoice={setDistChoice}/>}/>
                    <Route exact path="/edit-initiatives/:id" element={<EditInitiatives lang={lang} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>}/>
                    <Route path="/login" element={<LoginBlock lang={lang} />}/>
                    <Route path="/reg" element={<RegBlock lang={lang}/>}/>
                    <Route path="/forgot" element={<ForgotBlock lang={lang}/>}/>
                    <Route path="/rules" element={<RulesBlock lang={lang} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>}/>
                    <Route path="/about" element={<AboutProject lang={lang} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>}/>
                    <Route path="/workMap" element={<WorkMapPage lang={lang} setCatCardClick={setCatCardClick} setData={setData} setLang={setLang}/>}/>
                    <Route path="/initiative-map"
                           element={<IniMapPage
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               dataCat={dataCat}
                               setDataCat={setDataCat}
                               data={!tabBtn ? data?.filter(i => i.status.id === 1): data}
                               setClickInitiative={setClickInitiative}
                               setData={setData}
                               distChoice={distChoice}
                               setDistChoice={setDistChoice}
                               setMapCatClick={setMapCatClick}
                               tabBtn={tabBtn}
                               dataCardPas={dataCardPas}
                           />}/>
                    <Route path="/change-password/:token" element={<ChangePasBlock lang={lang}/>}/>
                    <Route path="/initiative"
                           element={<TableBlock
                               lang={lang}
                               setClickInitiative={setClickInitiative}
                               setCatCardClick={setCatCardClick}
                               data={data}
                               setData={setData}
                               setDistChoice={setDistChoice}
                               tabBtn={tabBtn}
                               setTabsKey={setTabsKey}
                               setResetTable={setResetTable}
                               archiveYears={archiveYears}
                               isYear={isYear}
                               setIsYear={setIsYear}
                           />}/>
                    <Route path="/initiative-moder"
                           element={<TableBlockModer
                               lang={lang}
                               setClickInitiative={setClickInitiative}
                               setCatCardClick={setCatCardClick}
                               data={data}
                               clickInitiative={clickInitiative}
                               setResetTable={setResetTable}
                               setTabsKey={setTabsKey}
                               setData={setData}
                               setDistChoice={setDistChoice}
                           />}/>
                    <Route path="/email-confirm/:token" element={<ConfirmEmail lang={lang}/>}/>
                    <Route path="/auth-social" element={<AuthSocial lang={lang}/>}/>
                    <Route path="*" element={<NotPage lang={lang} setCatCardClick={setCatCardClick} setData={setData} setDistChoice={setDistChoice}/>} />

                </Routes>
                <CardInitiativeBlock
                    lang={lang}
                    clickInitiative={clickInitiative}
                    setClickInitiative={setClickInitiative}
                    dataCardPas={dataCardPas}
                    setDataCardPas={setDataCardPas}
                    setResetTable={setResetTable}
                    tabsKey={tabsKey}
                    tabBtn={tabBtn}
                   />
                <FooterBlock lang={lang}/>
            </div>
        </ConfigProvider>
    );
};

export default Initiatives;
