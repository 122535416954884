import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { Button, Tooltip } from "antd";

import {ReactComponent as Mark} from '../Image/mark.svg'
import {ReactComponent as Address} from '../Image/address.svg'
import {ReactComponent as Fio} from '../Image/fio.svg'
import {ReactComponent as Comp} from '../Image/companu.svg'
import {ReactComponent as Tel} from '../Image/tel.svg'
import {ReactComponent as Mail} from '../Image/mail.svg'
import {ReactComponent as Clock} from '../Image/clok.svg'

import { lanGs } from "../langData";
import DEF from "../Image/def.svg";
import defImg from "../Image/degImg.png";

const CardItem = ({ setToMark, cardData, lang, cardSelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGallery, setIsGallery] = useState(null);

  // lang = 'nameKz'
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    /*  setIsModalPas(false);
      setItemsPas(false)*/
  };


  useEffect(() => {
    setIsGallery(null);
    cardData && cardSelect && setIsGallery({
      backgroundImage: `${cardSelect.subCategory.image}`,
      cursor: `${cardData?.images?.length > 0 ? "pointer" : "initial"}`
    });


  }, [cardData]);


  return (
    <div className={"sbcontent_wrap"}>

      {/*превью / дефолтная картинка / галерея */}
      <div className={"sbcontent_item_gallery"} style={{ backgroundImage: `url(${cardSelect?.subCategory?.image ? cardSelect.subCategory.image : defImg})` }}>
        <span style={isGallery}></span>
      </div>

      {/*строка с названием и кнопкой приблизить*/}
      {cardData?.name && <div className={"sbcontent_item btn_line name_block"}>
        <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={`${cardData.name[lang]}`}>
                           <span className={"short_name_2line"}>
                    {cardData.name[lang]}
                </span>
        </Tooltip>
        <Tooltip placement="right" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"} title={`${cardData?.marker ? `${lanGs["Приблизить"][lang]}`
          : `${lanGs["Нет метки"][lang]}`}`}>
          <Button type="primary" icon={<Mark />} className={`${cardData?.marker ? "mark_active" : "mark_dis"}`} onClick={() => {
            cardData?.marker && setToMark(cardData?.marker.coordinates);
          }} />
        </Tooltip>
      </div>}


      {/*категория*/}
      {(cardData?.subCategory || cardData?.status) && <div className={"sbcontent_items category"}>
        {cardData.subCategory &&
          <Tooltip
            placement="bottom"
            color={"#146eb0"}
            overlayClassName={"Tooltip_wrapper"}
          >
            <div className={"card_item category "}>
              <img src={`${cardData?.subCategory?.icon ? cardData.subCategory.icon : DEF}`} alt={"иконка категории"} />
              <span
                className={""}>{cardData?.subCategory ? cardData.category.name[lang] + " / " + cardData.subCategory.name[lang] : lanGs["Не указан"][lang]}</span>
            </div>
          </Tooltip>}
      </div>}

      {/*Расположение: Район / Адрес */}
      <div className={'sbcontent_items'}>
                <span className={'sbcontent_title'}>{lanGs['Расположение'][lang]}</span>
                <div className={'sbcontent_item text_data dist_line'}>
                    <span><Mark/> {lanGs['Район'][lang] + ': '}</span>
                    {cardData?.district  ?
                      <span>{cardData?.district?.id === 0 ? cardData.district[lang] : cardData.district[lang].slice(0, -6)}</span>:
                      <span>{lanGs['Не указан'][lang]}</span>}
                </div>
                <div className={'sbcontent_item text_data address_line'}>
                    <span><Address/> {lanGs['Адрес'][lang] + ': '}</span>
                    {cardData.address ? <Tooltip placement="topRight" color={'#146eb0'} overlayClassName={'Tooltip_wrapper'} title={`${cardData.address}`}>
                            <span className={'short_name_2line'}>
                                {cardData.address}
                            </span>
                    </Tooltip>: <span>{lanGs['Не указан'][lang]}</span>}
                </div>
            </div>

      {/*Сроки : даты / статус* */}
      {(cardData?.status?.length  || cardData?.startDate || cardData?.endDate) &&
        <div className={'sbcontent_items'}>
          <span className={'sbcontent_title'}>{lanGs['Сроки и статус'][lang]}</span>
          <div className={'sbcontent_item text_data dist_line'}>
            <span><Clock/> {lanGs['Сроки'][lang] + ': '}</span>
            {(cardData.startDate || cardData.endDate) &&
            <span>{cardData.startDate ? cardData.startDate : ''} - {cardData.endDate ? cardData.endDate : ''}</span>}
        </div>
        <div className={'sbcontent_item text_data '}>
          <div className={'style_status_dot'}><span style={{color: `${cardData?.status?.color}`}}>⬤</span> <span>{lanGs['Статус'][lang]}:</span>
          </div>
          {cardData.status && <span style={{color: `${cardData.status.color}`}}>{cardData?.status?.length ? cardData?.status[lang] : lanGs["Не указан"][lang]}</span>}
        </div>
            </div>}
      {/*Контакты: телефон / почта* */}
      {(cardData?.phone?.length || cardData?.email?.length) &&
        <div className={'sbcontent_items'}>
                <span className={'sbcontent_title'}>{lanGs['Контакты'][lang]}</span>
          {cardData?.phone?.length  && <div className={"sbcontent_item  text_data dist_line"}>
            <span><Tel /> {lanGs["Телефон"][lang] + ": "}</span>
            <Tooltip placement="topRight" color={"#146eb0"} overlayClassName={"Tooltip_wrapper"}
                     title={lanGs["Контактный номер телефона"][lang]}>
              <span>{cardData.phone}</span>
            </Tooltip>
          </div>}

          {cardData?.email?.length && <div className={"sbcontent_item  text_data address_line"}>
            <span><Mail /> {lanGs["Почта"][lang] + ": "}</span>
            <Tooltip
              placement="topRight"
              color={"#146eb0"}
              overlayClassName={"Tooltip_wrapper"}
              title={`${lanGs["Электронный адрес"][lang]}`}>
                               <span className={"short_name_2line"}>
                                {'cardData.email'}
                            </span>
            </Tooltip>
          </div>}
            </div>}

      {/*Описание*/}
       {cardData?.description && cardData.description.length > 0 && <div className={'sbcontent_items'}>
                <span className={'sbcontent_title'}>{lanGs['Описание'][lang]}</span>
                <div className={'sbcontent_item descriptio_line'}>{cardData.description}</div>


            </div>}

      {/*  {ParserInfoCard(cardData).map((i, index) => {
                return CardItemCreateBlock(i, index)
            })}*/}
      {/*{cardData?.images && <Modal open={isModalOpen}
                                        onCancel={handleCancel}
                                        footer={false}
                                        width={'100vw'}
                                        wrapClassName={'card_item_modal'}
                                        centered
                                        maskStyle={{background: 'rgba(38, 53, 78, 0.8)'}}>
                <ImageGallery
                    items={cardData?.images.map(i => {
                        return {
                            original: i.path,
                            originalClass: `${i.id}`
                        }
                    })}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showIndex={true}/>
            </Modal>}
*/}
    </div>
  );
};

export default CardItem;


