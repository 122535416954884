export const lanGs ={
    'Ваш Email успешно подтвержден': {
        nameRu: "Ваш Email успешно подтвержден",
        nameKz: "Сіздің Email сәтті расталды",
    },
    "Email уже подтвержден": {
        nameRu: "Email уже подтвержден",
        nameKz: "Email қазірдің өзінде расталды",
    },
    'Ошибка данных или пользователь не найден': {
        nameRu: "Ошибка данных или пользователь не найден",
        nameKz: "Деректер қатесі немесе пайдаланушы табылмады",
    },
    'Подтверждение Email:': {
        nameRu: "Подтверждение Email:",
        nameKz: "Email Растау:",
    },
    'Авторизоваться': {
        nameRu: "Авторизоваться",
        nameKz: "Кіру",
    },
    't_error': {
        nameRu: "Срок действия ссылки прошло",
        nameKz: "Сілтеменің қолданылу мерзімі өтті",
    },
    't_ok': {
        nameRu: "Срок действия ссылки прошло",
        nameKz: "Сілтеменің қолданылу мерзімі өтті",
    },

}
/*
  '': {
    nameRu: "",
    nameKz: "",
  },


{lanGs[''][lang]}
* */
