export const lanGs = {
  "Оставить отзыв": {
    nameRu: "Оставить отзыв",
    nameKz: "Пікір қалдыру",
  },
  "Инфраструктура города": {
    nameRu: "Инфраструктура города",
    nameKz: "Қаланың инфрақұрылымы",
  },

  "Центр развития города Алматы": {
    nameRu: "Центр развития города Алматы",
    nameKz: "Алматы қаласын дамыту орталығы",
  },
  "Акимат города Алматы": {
    nameRu: "Акимат  города Алматы",
    nameKz: "Алматы қаласының әкімдігі",
  },
  "Управление Цифровизации города Алматы": {
    nameRu: "Управление   Цифровизации города Алматы",
    nameKz: "Алматы қаласы Цифрландыру басқармасы",
  },
  'Вход': {
    nameRu: "Вход",
    nameKz: "Кіру",
  },
  "Главная страница": {
    nameRu: "Главная страница",
    nameKz: "Басты бет",
  },
  "Ситуационный центр": {
    nameRu: "Ситуационный центр",
    nameKz: "Ахуалдық орталық",
  },
  "Не указан": {
    nameRu: "Не указан",
    nameKz: "Көрсетілмеген",
  },
  "Выбор района": {
    nameRu: "Выбор района",
    nameKz: "Аудан таңдау",
  },
  'Поиск по названию': {
    nameRu: "Поиск по названию",
    nameKz: "Аты бойынша іздеу",
  },
  'Район': {
    nameRu: "Район",
    nameKz: "Аудан",
  },
  'Адрес объекта': {
    nameRu: "Адрес объекта",
    nameKz: "Объектінің мекенжайы",
  },
  'Нет метки': {
    nameRu: "Нет метки",
    nameKz: "Белгі жоқ",
  },
  'Приблизить': {
    nameRu: "Приблизить",
    nameKz: "Жуықтау",
  },
  'Расположение': {
    nameRu: "Расположение",
    nameKz: "Орналасқан жері",
  },
  'Адрес': {
    nameRu: "Адрес",
    nameKz: "Мекен-жайы",
  },
  'Описание': {
    nameRu: "Описание",
    nameKz: "Сипаттама",
  },
  'найдено': {
    nameRu: "найдено",
    nameKz: "табылды",
  },
  'Показать': {
    nameRu: "Показать",
    nameKz: "Көрсету",
  },
  'Очистить': {
    nameRu: "Очистить",
    nameKz: "Тазалау",
  },
  'Сбросить фильтры': {
    nameRu: "Сбросить фильтры",
    nameKz: "Сүзгілерді қалпына келтіріңіз",
  },
  'Сбросить': {
    nameRu: "Сбросить",
    nameKz: "Қалпына келтіру",
  },
  'Применить фильтры': {
    nameRu: "Применить фильтры",
    nameKz: "Сүзгілерді қолдану",
  }, 'Применить': {
    nameRu: "Применить",
    nameKz: "Қолдану",
  },
  'Категории': {
    nameRu: "Категории",
    nameKz: "Санаттар",
  },
  'Выбрано ': {
    nameRu: "Выбрано ",
    nameKz: "Таңдалған ",
  },
  'Контакты': {
    nameRu: "Контакты",
    nameKz: "Байланыс",
  },
  'Телефон': {
    nameRu: "Телефон",
    nameKz: "Телефон",
  }
  ,'Почта': {
    nameRu: "Почта",
    nameKz: "Пошта",
  },
  'Контактный номер телефона': {
    nameRu: "Контактный номер телефона",
    nameKz: "Байланыс телефон нөмірі",
  },
  'Электронный адрес': {
    nameRu: "Электронный адрес",
    nameKz: "Электрондық мекен-жайы",
  },
  'Деление по районам': {
    nameRu: "Деление по районам",
    nameKz: "Аудандар бойынша бөлу",
  },
  'Деление по кластерам': {
    nameRu: "Деление по кластерам",
    nameKz: "Кластер бойынша бөлу",
  },
  'Мониторинг районов': {
    nameRu: "Мониторинг районов",
    nameKz: "Аудандар мониторингі",
  },
  'Статус': {
    nameRu: "Статус",
    nameKz: "Мәртебесі",
  },
  'Статус работ': {
    nameRu: "Статус работ",
    nameKz: "Жұмыс мәртебесі",
  },
  'Сроки и статус': {
    nameRu: "Сроки и статус",
    nameKz: "Мерзімдері мен мәртебесі",
  },
  'Сроки': {
    nameRu: "Сроки",
    nameKz: "Мерзімдері",
  },
  'Не указано': {
    nameRu: "Не указано",
    nameKz: "Көрсетілмеген",
  },
  'Ответственные': {
    nameRu: "Ответственные",
    nameKz: "Жауапты",
  },
  'Куратор': {
    nameRu: "Куратор",
    nameKz: "Куратор",
  },
  'Подрядчик': {
    nameRu: "Подрядчик",
    nameKz: "Мердігер",
  },
  'Информация о подрядчике': {
    nameRu: "Информация о подрядчике",
    nameKz: "Мердігер туралы ақпарат",
  },
  'ФИО': {
    nameRu: "ФИО",
    nameKz: "ТАӘ",
  },
  'Отправить вашу оценку?': {
    nameRu: "Отправить вашу оценку?",
    nameKz: "Сіздің ұпайыңызды жібересіз бе?",
  },
  'Отправить': {
    nameRu: "Отправить",
    nameKz: "Жіберу",
  },
  'Отменить': {
    nameRu: "Отменить",
    nameKz: "Бас тарту",
  },
  'Ошибка отправки': {
    nameRu: "Ошибка отправки",
    nameKz: "Жіберу қатесі",
  },
  "Недовольны": {
    nameRu: "Недовольны",
    nameKz: "Бақытсыз",
  },
  "Довольны": {
    nameRu: "Довольны",
    nameKz: "Бақытты",
  },
  "Вы можете выбрать несколько": {
    nameRu: "Вы можете выбрать несколько",
    nameKz: "Сіз бірнеше таңдай аласыз",
  },
  "Не выбран отзыв": {
    nameRu: "Не выбран отзыв",
    nameKz: "Шолу таңдалмаған",
  },
  "Отзыв отправлен": {
    nameRu: "Отзыв отправлен",
    nameKz: "Кері байланыс жіберілді",
  },
  "Что": {
    nameRu: "Что",
    nameKz: "Не",
  },
  "понравилось": {
    nameRu: "понравилось",
    nameKz: "ұнады",
  },
  "не понравилось": {
    nameRu: "не понравилось",
    nameKz: "ұнамады",
  },
  "Вы хотите оставить обращение?": {
    nameRu: "Хотите оставить обращение?",
    nameKz: "Өтінішті қалдырғыңыз келе ме?",
  },
  "Вы будете перенаправлены на сайт": {
    nameRu: "Вы будете перенаправлены на сайт OpenAlmaty.",
    nameKz: "Сіз Open Almaty сайтына қайта бағытталасыз.",
  },
  "Пропустить": {
    nameRu: "Пропустить",
    nameKz: "Өткізіп жіберу",
  },
  "Перейти": {
    nameRu: "Перейти",
    nameKz: "Өту",
  },
  "Вы остались": {
    nameRu: "Вы остались",
    nameKz: "Сіз қалдыңыз",
  },
  "Положительный отклик": {
    nameRu: "Положительный отклик",
    nameKz: "Оң жауап",
  },
  "Отрицательный отклик": {
    nameRu: "Отрицательный отклик",
    nameKz: "Теріс жауап",
  }, "Фильтры": {
    nameRu: "Фильтры",
    nameKz: "Сүзгілер",
  },
  "Ответственный орган": {
    nameRu: "Ответственный орган",
    nameKz: "Жауапты орган",
  },
  "не выбран": {
    nameRu: "не выбран",
    nameKz: "таңдалмаған",
  },
  "Текущий год": {
    nameRu: "Текущий год",
    nameKz: "Ағымдағы жыл",
  },
  "Период проведения работ": {
    nameRu: "Период проведения работ",
    nameKz: "Жұмыстарды жүргізу кезеңі",
  },
};
