import React, {useRef, useState} from 'react';
import Slider from "react-slick";
import {  Tooltip } from 'antd';
import './ScrollBlock.css'
import {declOfNum, numIcon} from '../.../../../utils/helper'
import CardInitiativeBlock from "../element/CardInitiativeBlock";
import {lanGs} from "../../utils/lang/langScrollBlock";


const ScrollBlock = ({lang, clickInitiative, setClickInitiative, to10Data, dataCardPas, setDataCardPas}) => {

    const settings = {

        dots: false,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,

        centerMode: true,
        cssEase: 'linear',
/*        slidesToShow: 5,*/
        initialSlide:2,
        variableWidth: true,

    };
    return (
        <div className={'ScrollBlock_wrap'}>
            <span className={'ScrollBlock_title'} >{lanGs['ТОП-10 инициатив с наибольшим числом голосов'][lang]}</span>
            <Slider {...settings}>
                {to10Data?.map((i, index) => {

                    return <div className="carousel-item"  key={i.id + i.name}>
                        <div className="carousel-item_card" onClick={() => {
                            setClickInitiative(i.id)
                        }}>
                            <img src={numIcon[index]} alt="" className={'carousel-img-back'}/>
                            <img src={i.icon} alt="" className={'carousel-item_img'}/>
                            <Tooltip placement="topLeft" title={i.name}>
                                <span className={'carousel-item_text'}>{i.name}</span>
                            </Tooltip>

                            <div className={'carousel-item_value'}>
                                <span>{i.voteAmount}</span>
                                <span>{declOfNum(i.voteAmount, [`${lanGs['голос'][lang]}`, `${lanGs['голоса'][lang]}`,`${lanGs['голосов'][lang]}`])}</span>
                            </div>
                        </div>

                    </div>
                })}






            </Slider>
            <CardInitiativeBlock lang={lang} clickInitiative={clickInitiative} setClickInitiative={setClickInitiative} dataCardPas={dataCardPas} setDataCardPas={setDataCardPas}/>
        </div>
    );
};

export default ScrollBlock;
